var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-button",
    {
      class: _vm.noStyle ? "cog-button--no-style" : "cog-button",
      on: { click: _vm.handleClick }
    },
    [
      _vm.$slots.icon
        ? _c("span", { staticClass: "cog-button__icon" }, [_vm._t("icon")], 2)
        : _vm._e(),
      _vm._v(" "),
      !_vm.noStyle && _vm.hasText
        ? _c(
            "span",
            { staticClass: "cog-button__text" },
            [_vm._t("default")],
            2
          )
        : _vm._t("default"),
      _vm._v(" "),
      _vm.$slots.icon2
        ? _c("span", { staticClass: "cog-button__icon" }, [_vm._t("icon2")], 2)
        : _vm._e(),
      _vm._v(" "),
      [_vm._t("status-icon")]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }