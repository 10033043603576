<template>
	<header
		v-if="visible"
		class="cog-header"
		:class="[
			{
				'cog-wrapper': layout !== 'stretch',
				['cog-header--' + layout]: logo,
			},

			'cog-header--' + alignment
		]"
	>
		<img v-if="logo" :src="logo" :alt="alt || false">
		<div
			v-if="title"
			class="cog-header__text"
			:class="{'cog-wrapper': layout === 'stretch' || layout === 'logo-only'}"
		>
			<h1 class="cog-heading">{{ title }}</h1>
			<!-- eslint-disable-next-line vue/no-v-html -->
			<div v-if="description && !showConfirmation" class="cog-header__description" v-html="description"></div>
		</div>
	</header>
</template>

<script>
	export default {
		name: 'CHeader',
		props: {
			title: { default: null, type: String },
			description: { default: null, type: String },
			logo: { default: null, type: String },
			alt: { default: null, type: String },
			visible: { type: Boolean, default: true },
			showConfirmation: { type: Boolean, default: false },
			layout: {
				type: String,
				default: 'column',
				validator: value => ['column', 'row', 'stretch', 'logo-only'].includes(value)
			},
			alignment: {
				type: String,
				default: 'left',
				validator: value => ['left', 'center', 'right'].includes(value)
			}
		}
	};
</script>

<style lang="scss">
#{$specificity-base} {

	.cog-header {
		@include styled {

			.cog-header {
				background: var(--header__background-color, transparent);
				color: var(--header__color);

				h1 {
					font-family: var(--header__font-family, inherit);
					font-size: var(--header__font-size, inherit);
					font-weight: var(--header__font-weight, normal);
				}
			}
		}

		&.cog-wrapper {
			padding-top: var(--form__margins--responsive);
			padding-bottom: var(--header__padding-bottom);
		}

		& img {
			display: inline-block;
		}

		&--center:not(.cog-header--row) {
			text-align: center;
		}

		&--right {
			text-align: right;
		}

		&--row {

			&.cog-header--center {
				justify-content: center;
			}

			&.cog-header--right {
				flex-direction: row-reverse;
			}
		}

		&--stretch .cog-header__text {
			padding-top: var(--gutter);
			padding-bottom: var(--gutter);
		}

		&--stretch img {
			display: block;
			width: 100%;
		}

		&--logo-only .cog-header__text {
			display: none;
		}

		&--column img + .cog-header__text {
			padding-top: var(--gutter);
		}

		&__description {
			margin-top: .5em;

			& > *:last-child {
				margin-bottom: 0;
			}
		}
	}

	.cog-form[data-width~='500'] .cog-header {

		&--row {
			display: flex;
			align-items: center;

			&.cog-header--center .cog-header__text {
				max-width: 55%;
			}

			img {
				-ms-flex-negative: 0; // Fixes 16039 without causing 17383
				max-width: 25%;

				& + .cog-header__text {
					padding-left: var(--gutter);
				}
			}

			&.cog-header--right img + .cog-header__text {
				padding-right: var(--gutter);
				padding-left: 0;
			}
		}
	}

	.cog-form:not([data-width~='500']) .cog-header {

		&--center {
			text-align: center;
		}

		&--row img {
			max-height: 25vh;
		}
	}
}
</style>