import type { VueConstructor } from 'vue';
import type Vue from 'vue';
export function defineBreakWordsFilter(vue: VueConstructor<Vue>) {
	vue.filter('breakWords', value => {
	// https://cognitoforms.visualstudio.com/Cognito%20Forms/_workitems/edit/16130
		if (!value) return '';
		return value
			.replace(/(\/)/g, '$1\u200B')
			.replace(/(\\)/g, '$1\u200B')
			.replace(/(@)/g, '$1\u200B')
			.replace(/(_)/g, '$1\u200B');
	});
}