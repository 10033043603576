import { Deferred } from './deferred';

/**
 * Returns a function which, when called, will in turn call the given {callback} function once,
 * after the returned function has not been called within the {time} interval. The callback function
 * is called with the arguments that are passed to it in the _last_ call.
 *
 * This can be used to ensure that a given function is not called multiple times in rapid succession.
 * @param callback The target callback function to call.
 * @param time The amount of time to wait before calling the callback function.
 * @returns A function which may be used in place of the callback function.
 */
export default function debounce<Arguments extends unknown[], ReturnType>(
	callback: (...args: Arguments) => Promise<ReturnType> | ReturnType,
	time: number = 200
): (...args: Arguments) => Promise<ReturnType> {
	let interval = 0;
	let deferred: Deferred<ReturnType> = null;
	return (...args) => {
		// If the function has previously been called, then clear the timeout
		// Otherwise, set up a deferred to use for promise resolution
		if (interval)
			clearTimeout(interval);
		else
			deferred = new Deferred<ReturnType>();

		// Set a timeout to fire in {time} milliseconds
		interval = window.setTimeout(async () => {
			// Capture the deferred and null out closure variables,
			// this creates a new "debouncing timeframe"
			const thisDeferred = deferred;
			interval = 0;
			deferred = null;

			// Await the result of the callback (if it is asynchronous)
			try {
				const result = await callback(...args);

				// Resolve the promise with the resulting value
				thisDeferred.resolve(result);
			}
			catch (e) {
				thisDeferred.reject(e);
			}
		}, time);

		// Return the deferred promise so that the caller can await the result
		return deferred.promise;
	};
}

export function uiDebounce<Arguments extends unknown[]>(
	callback: (...args: Arguments) => void
): (...args: Arguments) => void {
	let rafId;

	return (...args) => {
		// If it is currently handling a request, cancel it
		if (rafId)
			cancelAnimationFrame(rafId);

		rafId = requestAnimationFrame(() => callback(...args));
	};
}
