import Vue from 'vue';
import { preventVueObservability } from '@cognitoforms/vuemodel';
import type { EntityOfType } from '@cognitoforms/model.js';
import type FormBase from 'src/components/FormBase';
import type { ResumeEntryResult } from '../web-api/entry-service';
import { ResumeMode, tryParseEntryToken, validateEntry } from '../web-api/entry-service';
import type { FormsModel } from './forms-model';
import { SessionService } from '../web-api/session-service';
import { FormService } from 'src/web-api/form-service';
import type { FormHandle } from './public/form-handle';
import { EmbedContext } from './public/form-handle';
import { Deferred } from 'src/util/deferred';
import FileService from 'src/web-api/file-service';
import { FormEvents } from './public/events';
import type EntryViewService from 'src/web-api/entry-views/entry-view-service';
import type { FormSession } from 'src/web-api/form-session';
import type QuantityService from 'src/web-api/quantity-service';
import elementLocalization from '@cognitoforms/element-ui/lib/locale';
import { configureElementLocalization } from 'src/localization/element-localization';
import Log from 'src/framework/logging/log';
import type { FormEntry } from '@cognitoforms/types/server-types/forms/model/form-entry';
import type { CombinedVueInstance } from 'vue/types/vue';
import { defineBreakWordsFilter } from './break-words-filter';
import type FormDefinition from './form-definition';
import type { Reference } from 'src/util/reference';
import { WriteableReference } from 'src/util/reference';
import { PreviewEntryService } from 'src/web-api/preview-entry-service';
import { EntriesEntryService } from 'src/web-api/entries-entry-service';
import { capitalizeKeys } from 'src/util/helpers';
import ConcurrentEntryService from 'src/web-api/concurrent-entry-service';
import type { UserInfo } from '@cognitoforms/types/server-types/forms/model/user-info';
import JSON5 from 'json5';
import { InvalidSharedLinkError } from 'src/web-api/base-service';
import type AuditService from 'src/web-api/audit-service';
import type PaymentService from 'src/web-api/payment-service';
import type { EntryKey } from 'src/web-api/entry-views/entry-view-types';
import EntrySet from 'src/web-api/entry-views/entry-set';
import { getErrorMessage } from 'src/util/promises';
import { ViewType } from '@cognitoforms/types/server-types/forms/model/view-type';
import { FormViewFormHandle } from './admin/form-view-form-handle';
import type { GoogleMapsLoader } from 'src/components/Address/google-maps-loader';
import type { FormEntryPsuedoProperties, FormEntryWorkflowExtensions } from 'src/framework/model/extensions/form-entry-extensions';
import { rebuildOrder } from 'src/framework/payment/order-builder';
import type { WorkflowRole } from '@cognitoforms/types/server-types/forms/model/workflow-role';

defineBreakWordsFilter(Vue);

function preventObservability(...objects) {
	objects.forEach(preventVueObservability);
}

function getEntryService(embedContext: EmbedContext, session: FormSession, formJson: string) {
	if (embedContext === EmbedContext.Preview)
		return new PreviewEntryService(session, formJson);
	else if (embedContext === EmbedContext.Entries || embedContext === EmbedContext.FormView) {
		return new ConcurrentEntryService(session, true);
	}
	else
		return new ConcurrentEntryService(session, false);
}

export async function getEntryViewServiceIfNeeded(session: FormSession, model: FormsModel, log: Log, embedContext: EmbedContext, pseudoLoadDeleted = false) {
	let entryViewService: EntryViewService;
	if (session.flags.hasLookups || embedContext === EmbedContext.Entries || embedContext === EmbedContext.FormView) {
		entryViewService = await (async () => {
			const EntryViewService = await import(
				/* webpackChunkName: "entry-view-service" */
				'src/web-api/entry-views/entry-view-service').then(m => m.default);
			return new EntryViewService(model, session, log, pseudoLoadDeleted);
		})();
	}
	return entryViewService;
}

async function getQuantityServiceIfNeeded(session: FormSession, hasInitialState: boolean) {
	let quantityService: QuantityService;
	if (session.flags.hasQuantityLimits) {
		quantityService = await (async () => {
			const QuantityService = await import(
				/* webpackChunkName: "quantity-service" */
				'src/web-api/quantity-service').then(m => m.default);
			return new QuantityService(hasInitialState, session);
		})();
	}
	return quantityService;
}

async function getAuditServiceIfNeeded(session: FormSession) {
	let auditService: AuditService;
	if (session.flags.formTracking) {
		auditService = await (async () => {
			const AuditService = await import(
				'src/web-api/audit-service').then(m => m.default);
			return new AuditService(session);
		})();
	}
	return auditService;
}

async function getPaymentServiceIfNeeded(session: FormSession) {
	let paymentService: PaymentService;
	if (session.flags.paypal) {
		paymentService = await (async () => {
			const PaymentService = await import(
				'src/web-api/payment-service').then(m => m.default);
			return new PaymentService(session);
		})();
	}
	return paymentService;
}

async function getGoogleMapsLoaderIfNeeded(session: FormSession) {
	let googleMapsLoader: GoogleMapsLoader;
	if (session.flags.addressAutocomplete && session.addressAutocompleteKey) {
		googleMapsLoader = await (async () => {
			const GoogleMapsLoader = await import('src/components/Address/google-maps-loader')
				.then(m => m.GoogleMapsLoader);

			return new GoogleMapsLoader(session.addressAutocompleteKey);
		})();
	}
	return googleMapsLoader;
}

/**
	 * Mounts invalid form view
	 * @param el The element to mount to
	 * @param type The type of invalid form to render
	 * @param $resource The resource for the component to use
	 */
async function mountInvalidFormView(el: HTMLElement | string, type: 'suspicious-content' | 'invalid-share-link' | 'form-not-found') {
	const CInvalidForm = await import('src/components/InvalidForm.vue').then(m => m.default);
	const invalidForm = new Vue({
		name: 'InvalidForm',
		components: { CInvalidForm },
		template: `<c-invalid-form type="${type}" />`
	});
	invalidForm.$mount(el);
}

/**
	 * Mounts require authentication embedded view
	 * @param el The element to mount to
	 * @param data The props needed for view
	 */
async function mountRequireAuthenticationView(el: HTMLElement | string, formUrl: string, linkText: string, entryToken: string) {
	const CRequireAuthenticationEmbed = await import('src/components/RequireAuthenticationEmbed.vue').then(m => m.default);

	let targetElement;
	if (typeof el === 'string')
		targetElement = document.querySelector<HTMLElement>(el);
	else
		targetElement = el;

	if (targetElement) {
		// Get token if applicable
		if (entryToken)
			formUrl = formUrl + '#' + entryToken;
		else if (window.location.href.includes('#'))
			formUrl = formUrl + window.location.href.substring(window.location.href.indexOf('#'));

		// Remove cog loader
		const loader = document.getElementsByClassName('cog-loader');
		if (loader.length > 0)
			loader[0].parentNode.removeChild(loader[0]);

		const requireAuth = new Vue({
			name: 'RequireAuthenticationEmbed',
			components: { CRequireAuthenticationEmbed },
			template: `<c-require-authentication-embed formUrl="${formUrl}" linkText="${linkText}" />`
		});
		requireAuth.$mount(el);
	}
}

export default class InternalApi {
	private readonly forms = new Map<string, Promise<FormDefinition>>();
	private readonly sessionService: SessionService;
	private static readonly instances = new Map<string, InternalApi>();

	private constructor(apiKey: string) {
		this.sessionService = new SessionService(apiKey);
		InternalApi.instances.set(apiKey, this);
	}

	static get(apiKey: string): InternalApi {
		let instance = InternalApi.instances.get(apiKey);
		if (!instance)
			instance = new InternalApi(apiKey);
		return instance;
	}

	establishSession(formId: string, embedContext: EmbedContext, formJson?: string): Promise<FormSession> {
		const { entryToken } = tryParseEntryToken(location.hash);
		return this.sessionService.newSession(formId, embedContext, formJson, entryToken);
	}

	async getFormDefinition(session: FormSession, embedContext: EmbedContext, formJson?: string): Promise<FormDefinition> {
		const formId = session.formId;

		// Return the existing promise if it exists to avoid duplicate loading
		if (embedContext !== EmbedContext.Preview && this.forms.has(formId))
			return this.forms.get(formId);

		if (session.formStatus === 'Disabled') {
			// Throw an error to signal to the caller that the form definition is not available
			throw new Error(`Form ${formId} is disabled.`);
		}

		// Add the promise to our collection to prevent duplicate loading
		const promise = new Deferred<FormDefinition>();
		if (embedContext !== EmbedContext.Preview)
			this.forms.set(formId, promise.promise);

		const formService = new FormService(session);

		const definition = embedContext === EmbedContext.Preview
			? formService.getPreviewDefinition(formJson)
			: formService.getDefinition(embedContext);

		// Fetch the form definition and resolve when it completes
		definition.then(formDef => promise.resolve(formDef))
			.catch(e => {
				console.error(e);
				// errorMessage will be undefined if e is not an Error or a string
				const errorMessage = getErrorMessage(e);
				formService.reportLoadingError(formId, errorMessage);
			});

		return promise;
	}

	async performAction(session: FormSession, entryId: string, action: string, roleName: string, userInfo: UserInfo, viewId: string) {
		const formDef = await this.getFormDefinition(session, EmbedContext.Entries);
		const model = await formDef.getIsolatedModel('performAction');
		const entriesEntryService = new EntriesEntryService(session);
		const concurrentEntryService = new ConcurrentEntryService(session, true);
		concurrentEntryService.registerView(viewId, ViewType.Table);
		await getEntryViewServiceIfNeeded(session, model, new Log(session), EmbedContext.Entries);

		const entryState = await entriesEntryService.getEntry(entryId);
		entryState.Entry.User = userInfo;
		entryState.Entry.Role = roleName;

		const entry = await model.constructEntry(entryState, true);
		entry.Entry.Action = action;

		if (validateEntry(entry)) {
			return concurrentEntryService.submit(entry, undefined, false, undefined);
		}

		return false;
	}

	async mountEntryPayment(session: FormSession, mountPoint: HTMLElement, entryKey: EntryKey, viewId: string, role: WorkflowRole, viewToken: string, paymentProcessor: string, currencyCode: string) {
		const formDef = await this.getFormDefinition(session, EmbedContext.Entries);
		const model = await formDef.getIsolatedModel('mountEntryPayment');
		model.resetEntry('Forms.FormEntry', entryKey.EntryId);
		const log = new Log(session);
		const entryViewService = await getEntryViewServiceIfNeeded(session, model, log, EmbedContext.Entries);
		entryViewService.registerViewToken(viewId, viewToken);
		const entrySet = await EntrySet.get(entryViewService, model.entryTypeName, viewId, role);

		const entry = await entrySet.getEntryByKey(entryKey, true);
		const formsModel = model;

		rebuildOrder(entry, entry.Order, { requirePayment: true });

		const paymentService = await getPaymentServiceIfNeeded(session);

		// Prevent Vue obserability of things that shouldn't be made observable (not entities and aren't used in templates and shouldn't be reactive)
		preventObservability(session, formDef, formDef.model, formDef.model.model.$resources, formDef.model.model.$culture, log, paymentService);

		const props = {
			propsData: {
				entry: entry,
				order: entry.Order,
				session: session,
				$resource: formsModel.model.getResource.bind(formsModel.model),
				log: log,
				$culture: formsModel.model.$culture,
				currencyCode: currencyCode,
				$format: formsModel.format.bind(formsModel),
				paymentService: paymentService,
				paymentProcessor: paymentProcessor
			}
		};

		const EntryPaymentInfo = Vue.extend(await import('src/components/EntryPaymentInfo.vue').then(m => m.default));
		const entryPaymentInfo = new EntryPaymentInfo(props);

		const ready = new Deferred();
		entryPaymentInfo.$once('ready', () => {
			ready.resolve();
		});

		entryPaymentInfo.$mount(mountPoint);

		await ready;

		return entryPaymentInfo;
	}

	async getAllAllowedActions(session: FormSession, entryView: string, entryKeys: EntryKey[], roleName: string, userInfo: UserInfo, actions: Set<string>, viewToken: string, callback: (actionName: string) => void, cancelPromise: Deferred<boolean>) {
		const formDef = await this.getFormDefinition(session, EmbedContext.Entries);
		const model = await formDef.getIsolatedModel('performAction');
		const entryViewService = await getEntryViewServiceIfNeeded(session, model, new Log(session), EmbedContext.Entries);
		entryViewService.registerViewToken(entryView, viewToken);
		const allowedActions = new Set<string>();
		const entrySet = await EntrySet.get(entryViewService, model.entryTypeName, entryView);

		while (entryKeys.length) {
			const keyBatch = entryKeys.splice(0, 100);
			await entryViewService.loadEntryData(entryView, false, ...keyBatch);
			for (const key of keyBatch) {
				if (!cancelPromise.pending)
					return;
				const entryData = await entrySet.getEntryJson(key);

				entryData.Entry.User = userInfo;
				entryData.Entry.Role = roleName;
				const entry = await model.constructEntry<FormEntry & FormEntryWorkflowExtensions>(entryData, true);
				for (const action of entry.Allowed_Actions) {
					if (!allowedActions.has(action.ActionName) && actions.has(action.ActionName)) {
						callback(action.ActionName);
						allowedActions.add(action.ActionName);

						if (allowedActions.size === actions.size)
							return;
					}
				}
			}
		}
	}

	async mount(el: HTMLElement | string, handle: FormHandle, session: FormSession, formDef: FormDefinition, embedContext: EmbedContext, formJson?: string): Promise<Reference<FormBase>> {
		const formId = handle.formId;
		let handleIsReady = false;

		if (session.formStatus === 'AutoDisabled') {
			// Render the invalid/suspicious form component, completely independent from the actual form (i.e `FormBase`)
			await mountInvalidFormView(el, 'suspicious-content');

			// Throw an error to signal to the caller that the form was not mounted successfully
			throw new Error(`Form ${handle.formId} is disabled.`);
		}

		if (!formDef) {
			await mountInvalidFormView(el, 'form-not-found');
			throw new Error(`No form with id ${formId} has been defined.`);
		}

		const log = new Log(session);
		const entryService = getEntryService(embedContext, session, formJson);

		let entryState: any = {};
		let entryHash: any;
		let resume: ResumeEntryResult;
		// Check the url for entry resume token and download the state for the token

		handle.once(FormEvents.EntryToken, async e => {
			if (handleIsReady)
				console.warn('entryToken() must be called immediately after mount(). The following prefill data was not used:', e.data);
			else
				entryHash = e.data.entryToken;
		});

		handle.on(FormEvents.OverrideText, e => {
			if (handleIsReady)
				console.warn('overrideText() must be called immediately after mount(). The following resource data was not used:', e.data);
			else
				formDef.model.overrideResource(e.data.resourceName, e.data.value);
		});

		// Listen for prefill
		handle.once(FormEvents.Prefill, async e => {
			if (handleIsReady)
				console.warn('prefill() must be called immediately after mount(). The following prefill data was not used:', e.data);
			else {
				const { json } = e.data;
				const data = JSON5.parse(json);
				entryState = capitalizeKeys(data);
				delete entryState['Id'];
			}
		});

		let form: CombinedVueInstance<FormBase, object, object, object, Record<never, any>>;

		const formRef: WriteableReference<FormBase> = new WriteableReference();

		const formsModel = formDef.model;

		const FormComponent = formDef.component;

		// do not construct the entry or mount the form until the form handle is ready, allowing the user to prefill the entry state
		handle.ready.then(async () => {
			const entryViewService = await getEntryViewServiceIfNeeded(session, formsModel, log, embedContext);
			const fileService = new FileService(formsModel, session, log);

			handleIsReady = true;

			let notAvailableMessage = '';
			const { success: couldParseEntryToken, entryToken } = tryParseEntryToken(entryHash || location.hash);
			if (couldParseEntryToken) {
				try {
					resume = await entryService.resume(formId, entryToken);
					entryState = resume.entry;

					if (resume.userEmail)
						entryState.Entry.User = { Email: resume.userEmail };

					if (resume.role)
						entryState.Entry.Role = resume.role;

					if (resume.order)
						entryState.Order = resume.order;
				}
				catch (e) {
					console.error(new Error('Invalid share link.'));
					session.isValidSharedLink = false;

					if (e instanceof InvalidSharedLinkError) {
						notAvailableMessage = e.notAvailableMessage;
					}
				}
			}

			if (session.flags.requireAuthenticationEmbedded) {
				// Render the require authentication on embedded form component, completely independent from the actual form (i.e `FormBase`)
				await mountRequireAuthenticationView(el, session.formUrl, session.linkText, entryToken);

				// Throw an error to signal to the caller that the form was not mounted successfully
				throw new Error(`Form ${handle.formId} requires authentication to access.`);
			}

			let entry: EntityOfType<FormEntry & FormEntryPsuedoProperties>;
			if (entryState.Entry == null)
				entryState.Entry = {};

			if (!entryState.Entry.Role)
				entryState.Entry.Role = handle instanceof FormViewFormHandle ? handle.getRoleName() : session.publicRole;

			// Overwrite resumed entry's userInfo if session's userInfo has a value
			if (session.userInfo && session.userInfo.Email)
				entryState.Entry.User = session.userInfo;

			try {
				entry = await formsModel.constructEntry(entryState);
			}
			catch (err) {
				entry = await formsModel.constructEntry();
				console.warn('Unable to construct entry with state:', entryState, err);
			}

			const auditService = await getAuditServiceIfNeeded(session);
			const quantityService = await getQuantityServiceIfNeeded(session, !entry.meta.isNew && entry.Entry.Status !== 'Incomplete');
			const paymentService = await getPaymentServiceIfNeeded(session);
			const googleMapsLoader = await getGoogleMapsLoaderIfNeeded(session);

			log.registerEntry(entry);

			// Get locale, localized resources, and culture from model options
			const cultureInfo = formDef.model.model.$culture;
			const locale = formDef.model.model.$locale;
			const localizedResources = formDef.model.model.$resources[locale];

			// Configure Element to use the locale defined for the form
			// https://element.eleme.io/?ref=madewithvuejs.com#/en-US/component/i18n
			configureElementLocalization(elementLocalization, locale, localizedResources, cultureInfo);

			session.registerResources(localizedResources);

			// Prevent Vue obserability of things that shouldn't be made observable (not entities and aren't used in templates and shouldn't be reactive)
			preventObservability(handle, formDef, formDef.model, formDef.model.model.$namespace, formDef.model.model.$resources, formDef.model.model.$culture, session, log, entryService, entryViewService, quantityService, fileService);
			const notOnEntriesPage = !(embedContext === EmbedContext.FormView || embedContext === EmbedContext.Entries);

			const formOptions = {
				propsData: {
					entry,
					readonly: (resume && resume.mode === ResumeMode.View) || session.isReadonly,
					startingPage: entry.Entry ? (resume && parseInt(resume.lastPageViewed)) || 1 : 1,
					publicEvents: handle,
					formsModel: formsModel,
					session,
					log,
					entryToken,
					entryService,
					entryViewService,
					quantityService,
					auditService,
					paymentService,
					fileService,
					googleMapsLoader,
					chameleon: formDef.theme.isChameleon && notOnEntriesPage,
					notAvailableMessage,
					showSaveAndResumeDialog: notOnEntriesPage,
					useThemeSettings: notOnEntriesPage,
					$resource: formDef.model.model.getResource.bind(formDef.model.model),
					$culture: formsModel.model.$culture,
					$format: formsModel.format.bind(formsModel),
					$parse: formsModel.parse.bind(formsModel),
					$expandDateFormat: formsModel.expandDateFormat.bind(formsModel),
					$namespace: formsModel.model.$namespace,
					showPageBreaks: handle instanceof FormViewFormHandle ? handle.getShowPageBreaks() : session.flags.paging
				}
			};

			form = new FormComponent(formOptions);
			if (quantityService)
				quantityService.initialize(entry);

			// Calculate form availablility for new entries in public contexts
			const publicContexts = [
				EmbedContext.Public,
				EmbedContext.Iframe,
				EmbedContext.Seamless
			];

			// We respect form availability in a FormView embed context
			const shouldCheckFormViewAvailability = embedContext === EmbedContext.FormView && handle instanceof FormViewFormHandle && handle.getIsPublic();
			if ((publicContexts.includes(embedContext) || shouldCheckFormViewAvailability) && entry.Id === null) {
				const formAvailableProp = entry.meta.type.properties.find(prop => prop.name === 'Form_Available');
				form.available = session.flags.available;

				// Only delay form load if form availability is conditional
				if (formAvailableProp) {
					if (formAvailableProp.isCalculated) {
						// If this form has lookups, wait for any async entry loading to complete
						if (session.flags.hasLookups)
							await entryViewService.loadingComplete;

						// Wait until all quantities have loaded
						if (quantityService)
							await quantityService.ready;
					}

					form.available = form.available && entry.Form_Available;
				}
			}

			form.$mount(el);

			formRef.value = form;
		}).catch(err => {
			log.error(err);
		});

		return formRef;
	}

	async deleteFormDef(formId: string) {
		if (this.forms.has(formId)) {
			// Destroy the form definition, which will clean up any state in the DOM
			const formDef = await this.forms.get(formId);
			if (formDef)
				formDef.destroy();

			// Remove the form definition from the cache
			this.forms.delete(formId);
		}
	}
}
