<template>
	<component
		:is="fieldContainerType"
		v-if="render"
		ref="field"
		:aria-hidden="containerOnly"
		:class="{
			'cog-field': !isTable,
			'is-focus': isFocused,
			['cog-field--' + fieldIndex]: fieldIndex,
			['cog-col ' + colNumClass]: !isTable,
			['cog-' + type]: type,
			[subtypeClass]: subtype,
			[customClass]: customClass,
			'is-error': showValidation && !containerOnly,
			'is-required': $source.required,
			'cog-hidden-validation': validationOnly,
			'cog-force-shown': forceShown
		}"
	>
		<template v-if="!validationOnly && !containerOnly">
			<label
				:is="labelType"
				v-if="!isTable"
				:id="labelType === 'label' ? `cog-${$source.id}-label` : false"
				:for="labelType === 'label' ? 'cog-'+$source.id : false"
				class="cog-label" :class="{ 'cog-offscreen': !showLabel }"
			>
				<!-- The asterisk and the label need to be on the same line in the markup. -->
				{{ $source.label | breakWords }}<span v-if="showAsterisk" aria-hidden="true" class="cog-asterisk">*</span>
				<span v-if="showAsterisk && !showValidation" class="cog-offscreen">({{ $resource("required-asterisk") }})</span>
				<span v-if="showValidation" class="cog-offscreen">({{ firstError.message }})</span>
			</label>
			<slot :field="$source" :is-table="isTable" :focus-lost="handleFocusLost"></slot>
			<div v-if="!isTable && (subtype !== 'checkboxes' || subtype !== 'radiobuttons')" class="cog-field__placeholder cog-input">
				<div class="cog-field__placeholder-inner">
					&nbsp;
				</div>
			</div>
			<!-- eslint-disable-next-line vue/no-v-html -->
			<div v-if="!isTable && $source.helptext && !$source.readonly" class="cog-helptext cog-html" v-html="$source.helptext"></div>
		</template>
		<transition v-if="!containerOnly" name="cog-error-message">
			<div v-if="!duplicateError && showValidation" class="cog-error-message cog-error-message--container-footer">{{ firstError.message }}</div>
		</transition>
		<span v-if="isTable && showValidation && !containerOnly && (!$source.readonly || isQuantityLimitError(firstError))" :title="firstError && firstError.message" class="cog-error-message__icon-container">
			<i-error class="cog-error-message__icon" />
		</span>
	</component>
</template>

<script>
	import '../style/_field.scss';
	import '../style/_field-placeholders.scss';
	import '../style/_error-message.scss';
	import '../style/_motion.scss';

	import ValidatedSourcePath from '../mixins/validated-source-path';
	import Col from './Col.vue';
	import showLabel from 'src/mixins/show-label';
	import IError from '../assets/error-outline.svg';
	import { findContainer } from '../util/dom';
	import { FormEvents } from 'src/framework/public/events';

	const fieldsetList = ['address', 'name', 'radiobuttons', 'checkboxes'];

	function shouldValidateWhileFocused(type, subtype) {
		return ['file'].includes(type) || ['checkbox', 'checkboxes', 'toggle'].includes(subtype);
	}

	export default {
		name: 'CField',
		components: { IError },
		mixins: [ValidatedSourcePath, Col, showLabel],
		inject: ['$resource', 'quantityService', 'formEvents'],
		props: {
			hideLabel: Boolean,
			isTable: { type: Boolean, default: false },
			render: { type: Boolean, default: true },
			type: { type: String, default: null },
			subtype: { type: String, default: null },
			customClass: { type: String, default: null },
			containerOnly: { type: Boolean, default: false },
			validationOnly: { type: Boolean, default: false },
			fieldIndex: { type: [String, Number], default: null },
			forceShown: { type: Boolean, default: false },
			limitQuantities: { type: Boolean, default: false }
		},
		data() {
			return {
				duplicateError: false,
				isFocused: null
			};
		},
		computed: {
			fieldContainerType() {
				return this.$source && (fieldsetList.includes(this.type) || fieldsetList.includes(this.subtype)) ? 'fieldset' : this.isTable ? 'td' : 'div';
			},
			labelType() {
				return this.$source && (fieldsetList.includes(this.type) || fieldsetList.includes(this.subtype)) ? 'legend' : 'label';
			},
			ignoreError() {
				return this.containerOnly;
			},
			subtypeClass() {
				if (this.subtype && this.subtype !== 'none')
					return 'cog-' + this.type + '--' + this.subtype
						.replace(/usphone|usaddress/, 'us')
						.replace(/internationalphone|internationaladdress/, 'international');
				else
					return null;
			},
			showAsterisk() {
				return this.$source.required && this.type !== 'yesno' && !this.form.isReadonly;
			}
		},
		created() {
			this.initiateQuantityLimits();

			if (shouldValidateWhileFocused(this.type, this.subtype))
				this.lostFocus = true;

			if (this.formEvents) {
				// Reset validation and update the quantity limits for entries page when the entry or forms readonly changes
				this.formEvents.on(FormEvents.ResetEntry, this.refreshField);
			}
		},
		beforeDestroy() {
			if (this.formEvents) {
				// Removes refreshField at end of lifecycle
				this.formEvents.off(FormEvents.ResetEntry, this.refreshField);
			}
		},
		methods: {
			handleFocusLost() {
				this.lostFocus = true;
			},
			preventDuplicateError() {
				this.$nextTick().then(() => setTimeout(() => {
					const container = findContainer(this, container => container.$options.name === 'CSection');
					if (this.validationOnly && this.hasError) {
						const message = this.firstError.message;
						const errors = Array.prototype.slice.call(container.$el.querySelectorAll('.cog-hidden-validation'));
						for (let i = 0; i < errors.length; i++) {
							// if this is hit first, then this is not a duplicate
							if (errors[i].__vue__ === this) {
								this.duplicateError = false;
								return;
							}
							// if an error that is not this but has the same message as this is hit first, then this is a duplicate
							else if (errors[i].__vue__ !== this && errors[i].textContent.trim() === message) {
								this.duplicateError = true;
								return;
							}
						}
						this.duplicateError = false;
					}
					else
						this.duplicateError = false;
				}));
			},
			initiateQuantityLimits() {
				if (this.limitQuantities) {
					this.$watch(() => [this.firstError, this.validationOnly], this.preventDuplicateError.bind(this));
					const forceValidation = this.forceValidation.bind(this);
					this.forceValidation = () => {
						forceValidation();
						this.preventDuplicateError();
					};
				}
			},
			refreshField() {
				if (this.limitQuantities)
					this.quantityService.refresh();

				this.isDirty = false;
				this.lostFocus = false;
				this.detectDirtyEntity();
				// Trigger the error change handler when the component is first refreshed in order to mark quantity limit errors as dirty.
				// The watcher may not fire in some cases, for example when switching entries.
				this.handleErrorChange(this.firstError);
			}
		}
	};
</script>
