<template>
	<div class="cog-choice">
		<slot :options="options"></slot>
		<div v-if="!$parent.isTable" class="cog-choice__placeholder" :class="[{'cog-input': !checkable}, checkable && columns ? `cog-inner-col cog-inner-col--css-columns cog-inner-col--${columns}` : null]">
			<div v-if="checkable">
				<div v-for="(choice, i) in choices" :key="i" :class="columns ? 'cog-inner-col__item' : null" class="cog-choice__checkable-placeholder">
					<span>{{ choice }}</span>
				</div>
				<div v-if="allowOther" class="cog-choice__allow-other-placeholder">
					<div class="cog-field__placeholder-inner">&nbsp;</div>
				</div>
			</div>
			<div v-else class="cog-field__placeholder-inner" :class="columns ? 'cog-inner-col__item' : null">
				&nbsp;
			</div>
		</div>
	</div>
</template>

<script>
	import { removeExtraSpace } from 'src/util/helpers';

	export default {
		name: 'CChoice',
		inject: ['$format'],
		inheritAttrs: false,
		props: {
			choices: { type: Array, required: true },
			showPrices: Boolean,
			columns: { type: Number, default: null },
			checkable: Boolean,
			allowOther: Boolean
		},
		data() {
			return {
				useChoiceLabelAsValue: true
			};
		},
		computed: {
			options() {
				return this.choices.filter(c => !!c.Label).map(c => ({
					displayValue: removeExtraSpace(this.showPrices && c.Price !== null ? c.Label + ' - ' + this.$format(c.Price, 'C') : c.Label),
					value: this.useChoiceLabelAsValue ? c.Label : String(c.Value)
				}));
			}
		}
	};
</script>

<style lang="scss">
#{$specificity-base} {
	// Inner Columns
	// in theory these inner-field columns could be used for
	// anything but currently they are only used for checkables.
	.cog-inner-col--no-css-columns {

		> * {
			display: flex;
			flex-wrap: wrap;
			width: calc(100% + 1em);
		}

		&.cog-inner-col--1 .cog-inner-col__item {
			width: 100%;
		}

		&.cog-inner-col--2 .cog-inner-col__item {
			width: 50%;
		}

		&.cog-inner-col--3 .cog-inner-col__item {
			width: 33.333%;
		}

		&.cog-inner-col--4 .cog-inner-col__item {
			width: 25%;
		}
	}

	.cog-inner-col--css-columns {

		&.cog-inner-col--1 {
			columns: 1 130px;
		}

		&.cog-inner-col--2 {
			columns: 2 130px;
		}

		&.cog-inner-col--3 {
			columns: 3 130px;
		}

		&.cog-inner-col--4 {
			columns: 4 130px;
		}
	}

	// Placeholder CSS
	// don't use checkable CSS because it hasn't loaded yet.
	:not(:empty) + .cog-choice__placeholder {
		display: none;
	}

	.cog-inner-col .cog-choice__checkable-placeholder {
		width: 100%;
	}

	.cog-choice__checkable-placeholder {
		margin-bottom: $gutter-half;
		padding-top: 1px;

		span {
			background: $bgColor;
			padding-left: calc(var(--checkable__scale) * 1.5em);
			color: transparent;
		}
	}

	.cog-choice__checkable-placeholder:not(:last-child) {
		margin-right: 12px;
	}

	:not(.cog-inner-col) > .cog-choice__checkable-placeholder {
		display: inline-block;
	}

	.cog-choice__allow-other-placeholder {
		display: inline-block;

		& > div {
			width: 130px;
		}
	}
}
</style>