// Adapted from https://github.com/darkskyapp/binary-search
/**
 * Insert a value into an array, using a key function and sort order to determine the insertion position.
 * @returns The position at which the item was inserted.
 */
export function binaryInsert<T>(arr: T[], incoming: T, getKey: (item: T) => number, ascending = true): number {
	const incomingKey = getKey(incoming);
	const comparator = (a: number, b: number) => (ascending ? a - b : b - a);

	let mid, cmp;
	let low = 0;
	let high = arr.length - 1;

	while (low <= high) {
		// The naive `low + high >>> 1` could fail for array lengths > 2**31
		// because `>>>` converts its operands to int32. `low + (high - low >>> 1)`
		// works for array lengths <= 2**32-1 which is also Javascript's max array
		// length.
		mid = low + ((high - low) >>> 1);
		cmp = comparator(getKey(arr[mid]), incomingKey);

		// Too low.
		if (cmp < 0.0)
			low = mid + 1;
		// Too high.
		else if (cmp > 0.0)
			high = mid - 1;
		// Key found.
		else
			break;
	}

	let insertPosition = ascending ? Math.min(low, high) : Math.max(low, high);

	if (low > high)
		insertPosition = low;

	insertPosition = Math.min(arr.length, Math.max(0, insertPosition));
	arr.splice(insertPosition, 0, incoming);
	return insertPosition;
};