import productSupportedLocales from './product-supported-locales.generated';
import elementSupportedLanguages from './element-supported-languages.generated';
import type { CultureInfo, LocalizedResources } from '@cognitoforms/model.js';

/**
 * Type definition for the element localization module (i.e. "/element-ui/lib/locale")
 */
export type ElementLocalizationModule = {
	use(lang: any): void;
	t(path: string, options: any): string;
	i18n(fn: (path: string, options: any) => string | null | void): void;
};

/**
* Override the element language code to use for some locales when the two don't match or are not a 1:1 mapping
*/
const productLocaleToElementLangOverrides: { [code: string]: string } = {
	// Chinese => Simplified Chinese
	'zh': 'zh-CN',

	// Norwegian
	'nb': 'nb-NO',

	// Swedish
	'sv': 'sv-SE',

	// Turkish
	'tr': 'tr-TR'
};

/**
 * Gets the element language code to use for the given locale code
 * @param locale The locale code (i.e. product language code, based on .NET cultures)
 */
export function getElementLanguageCode(locale: string): string {
	if (!productSupportedLocales.hasOwnProperty(locale)) {
		throw new Error(`Locale ${locale} is not supported, options: ${Object.keys(productSupportedLocales).join(',')}.`);
	}

	const localeName = productSupportedLocales[locale];

	const overrideElementLangCode = productLocaleToElementLangOverrides.hasOwnProperty(locale) ? productLocaleToElementLangOverrides[locale] : null;

	if (elementSupportedLanguages.hasOwnProperty(overrideElementLangCode || locale)) {
		return overrideElementLangCode || locale;
	}

	if (overrideElementLangCode) {
		throw new Error(`Locale '${locale}' (${localeName}) has an override of '${overrideElementLangCode}', which is not a valid language supported by Element, options: ${Object.keys(elementSupportedLanguages).join(',')}.`);
	}

	throw new Error(`Locale '${locale}' (${localeName}) does not map to a language supported by Element, options: ${Object.keys(elementSupportedLanguages).join(',')}.`);
}

/**
 * Imports an element language module for the given locale
 * @param locale The locale code of the language module to import
 */
export function importElementLanguage(locale: string): Promise<any> {
	if (locale === 'hi') {
		return import(/* webpackChunkName: '[request]' */ './element/hi').then(m => m.default);
	}

	const langCode = getElementLanguageCode(locale);
	return import(/* webpackChunkName: '[request]' */ `@cognitoforms/element-ui/lib/locale/lang/${langCode}`).then(m => m.default);
}

/**
 * Override the given resource path it the resource module, and track that it has been overridden
 * NOTE: Loosely based on the `t` function in element (see: src\locale\index.js)
 * @param obj The target object
 * @param path The path to set
 * @param newValue The new value to set
 */
export function overrideResourcePath(lang: any, path: string, newValue: string, createMissing: boolean = false): boolean | void {
	const array = path.split('.');
	let current = lang;
	let missing = false;
	for (let i = 0, j = array.length; i < j; i++) {
		const property = array[i];
		let value = current[property];
		const isLeafProp = i === j - 1;
		if (isLeafProp) {
			if (!current.hasOwnProperty(property) && !createMissing) {
				return;
			}
			let changed = false;
			if (missing || !value || value !== newValue) {
				changed = true;
			}
			current[property] = newValue;
			return changed;
		}
		else if (!value) {
			if (createMissing) {
				if (!missing) {
					missing = true;
				}
				value = current[property] = {};
			}
			else {
				return;
			}
		}
		current = value;
	}
}

/**
 * Update the given element language module using product localized resources and culture info
 * @param lang The language module to update
 * @param localizedResources The "dictionary" of localized resources
 * @param cultureInfo The current culture info object
 * @returns The list of resources that have been overridden
 */
export function updateElementLanguageModule(lang: any, localizedResources: LocalizedResources, cultureInfo: CultureInfo): string[] {
	// Keep track of language module paths that have been overridden
	const overriddenResourcePaths: string[] = [];

	// Defines the intended language module overrides
	const overrides = {
		'el.datepicker.now': localizedResources['field-datepicker-now'],
		'el.datepicker.today': localizedResources['today'],
		// 'el.datepicker.cancel': ???, // Only used by `time` and `time-range` components (which we don't use)
		'el.datepicker.clear': localizedResources['clear'],
		'el.datepicker.confirm': localizedResources['field-datepicker-confirm'],
		// 'el.datepicker.selectDate': ???, // Only used if `showTime` is true
		// 'el.datepicker.selectTime': ???, // Only used if `showTime` is true
		// 'el.datepicker.startDate': ???, // Only used by the `date-range` and `time-range` components
		// 'el.datepicker.startTime': ???, // Only used by the `time-range` component
		// 'el.datepicker.endDate': ???, // Only used by the `date-range` and `time-range` components
		// 'el.datepicker.endTime': ???, // Only used by the `time-range` component
		'el.datepicker.prevYear': localizedResources['field-datepicker-prev-year'],
		'el.datepicker.nextYear': localizedResources['field-datepicker-next-year'],
		'el.datepicker.prevMonth': localizedResources['field-datepicker-prev-month'],
		'el.datepicker.nextMonth': localizedResources['field-datepicker-next-month'],
		'el.datepicker.year': localizedResources['field-datepicker-year'],
		'el.datepicker.month1': cultureInfo.dateTimeFormat.MonthNames[0],
		'el.datepicker.month2': cultureInfo.dateTimeFormat.MonthNames[1],
		'el.datepicker.month3': cultureInfo.dateTimeFormat.MonthNames[2],
		'el.datepicker.month4': cultureInfo.dateTimeFormat.MonthNames[3],
		'el.datepicker.month5': cultureInfo.dateTimeFormat.MonthNames[4],
		'el.datepicker.month6': cultureInfo.dateTimeFormat.MonthNames[5],
		'el.datepicker.month7': cultureInfo.dateTimeFormat.MonthNames[6],
		'el.datepicker.month8': cultureInfo.dateTimeFormat.MonthNames[7],
		'el.datepicker.month9': cultureInfo.dateTimeFormat.MonthNames[8],
		'el.datepicker.month10': cultureInfo.dateTimeFormat.MonthNames[9],
		'el.datepicker.month11': cultureInfo.dateTimeFormat.MonthNames[10],
		'el.datepicker.month12': cultureInfo.dateTimeFormat.MonthNames[11],
		// 'el.datepicker.week': ???, // Only used if `showWeekNumber` prop is set (default is false)
		'el.datepicker.weeks.sun': cultureInfo.dateTimeFormat.AbbreviatedDayNames[0],
		'el.datepicker.weeks.mon': cultureInfo.dateTimeFormat.AbbreviatedDayNames[1],
		'el.datepicker.weeks.tue': cultureInfo.dateTimeFormat.AbbreviatedDayNames[2],
		'el.datepicker.weeks.wed': cultureInfo.dateTimeFormat.AbbreviatedDayNames[3],
		'el.datepicker.weeks.thu': cultureInfo.dateTimeFormat.AbbreviatedDayNames[4],
		'el.datepicker.weeks.fri': cultureInfo.dateTimeFormat.AbbreviatedDayNames[5],
		'el.datepicker.weeks.sat': cultureInfo.dateTimeFormat.AbbreviatedDayNames[6],
		'el.datepicker.months.jan': cultureInfo.dateTimeFormat.AbbreviatedMonthNames[0],
		'el.datepicker.months.feb': cultureInfo.dateTimeFormat.AbbreviatedMonthNames[1],
		'el.datepicker.months.mar': cultureInfo.dateTimeFormat.AbbreviatedMonthNames[2],
		'el.datepicker.months.apr': cultureInfo.dateTimeFormat.AbbreviatedMonthNames[3],
		'el.datepicker.months.may': cultureInfo.dateTimeFormat.AbbreviatedMonthNames[4],
		'el.datepicker.months.jun': cultureInfo.dateTimeFormat.AbbreviatedMonthNames[5],
		'el.datepicker.months.jul': cultureInfo.dateTimeFormat.AbbreviatedMonthNames[6],
		'el.datepicker.months.aug': cultureInfo.dateTimeFormat.AbbreviatedMonthNames[7],
		'el.datepicker.months.sep': cultureInfo.dateTimeFormat.AbbreviatedMonthNames[8],
		'el.datepicker.months.oct': cultureInfo.dateTimeFormat.AbbreviatedMonthNames[9],
		'el.datepicker.months.nov': cultureInfo.dateTimeFormat.AbbreviatedMonthNames[10],
		'el.datepicker.months.dec': cultureInfo.dateTimeFormat.AbbreviatedMonthNames[11],
		// 'el.select.loading': ???, // Option "loading" is not implemented
		'el.select.noMatch': localizedResources['field-choice-no-match'],
		// 'el.select.noData': ???, // The `no-data-text` prop is explicitly set to blank string
		// 'el.select.placeholder': ???, // Dictates default placeholder, but we bind the prop explicitly
		// 'el.cascader.noMatch': ???, // Component is not used
		// 'el.cascader.loading': ???, // Component is not used
		// 'el.cascader.placeholder': ???, // Component is not used
		// 'el.cascader.noData': ???, // Component is not used
		// 'el.pagination.goto': ???, // Component is not used
		// 'el.pagination.pagesize': ???, // Component is not used
		// 'el.pagination.total': ???, // Component is not used
		// 'el.pagination.pageClassifier': ???, // Component is not used
		// 'el.messagebox.title': ???, // Component is not used
		// 'el.messagebox.confirm': ???, // Component is not used
		// 'el.messagebox.cancel': ???, // Component is not used
		// 'el.messagebox.error': ???, // Component is not used
		// 'el.upload.deleteTip': ???, // Not used since we set `show-file-list` to false
		// 'el.upload.delete': ???, // Resource does not appear to be used in the element codebase
		// 'el.upload.preview': ???, // Resource does not appear to be used in the element codebase
		// 'el.upload.continue': ???, // Resource does not appear to be used in the element codebase
		// 'el.table.emptyText': ???, // Component is not used
		// 'el.table.confirmFilter': ???, // Component is not used
		// 'el.table.resetFilter': ???, // Component is not used
		// 'el.table.clearFilter': ???, // Component is not used
		// 'el.table.sumText': ???, // Component is not used
		// 'el.tree.emptyText': ???, // Component is not used
		// 'el.transfer.noMatch': ???, // Component is not used
		// 'el.transfer.noData': ???, // Component is not used
		// 'el.transfer.titles': ???, // Component is not used
		// 'el.transfer.filterPlaceholder': ???, // Component is not used
		// 'el.transfer.noCheckedFormat': ???, // Component is not used
		// 'el.transfer.hasCheckedFormat': ???, // Component is not used
		// 'el.image.error': ???, // Component is not used
		// 'el.pageHeader.title': ???, // Component is not used
		// 'el.popconfirm.confirmButtonText': ???, // Component is not used
		// 'el.popconfirm.cancelButtonText': ???, // Component is not used
		'el.spinner.increase': localizedResources['field-spinner-increase'],
		'el.spinner.decrease': localizedResources['field-spinner-decrease']
	};

	// Override the resources paths
	Object.keys(overrides).forEach(path => {
		const newValue = overrides[path];
		const overrideResult = overrideResourcePath(lang, path, newValue);
		if (typeof overrideResult === 'boolean')
			overriddenResourcePaths.push(path);
		else if (process.env.NODE_ENV === 'development')
			console.warn(`Resource path '${path}' could not be overridden.`);
	});

	return overriddenResourcePaths;
}

/**
 * Configure element localization for the given locale
 * @param elementLocalization The element localization module
 * @param locale The locale to configure
 * @param localizedResources The "dictionary" of localized resources for the target locale
 * @param cultureInfo The culture info object for the target locale
 */
export function configureElementLocalization(elementLocalization: ElementLocalizationModule, locale: string, localizedResources: LocalizedResources, cultureInfo: CultureInfo): Promise<void> {
	// Import the appropriate element language module for the given locale
	return importElementLanguage(locale).then(lang => {
		// Update the element language module to use Cognito resources and cultures, and record the resources that have been overridden
		const overriddenResourcePaths = updateElementLanguageModule(lang, localizedResources, cultureInfo);

		if (process.env.NODE_ENV === 'development') {
			// In development, override the i18n behavior to warn about resources that are not defined by Cognito localized resources and cultures
			elementLocalization.i18n((path: string) => {
				if (overriddenResourcePaths.indexOf(path) < 0) {
					console.warn(`Element resource '${path}' is not mapped to product resources, falling back to out of box language module...`);
				}
			});
		}

		// Set the appropriate language module as a fallback
		elementLocalization.use(lang);
	});
}
