<template>
	<c-button class="cog-button--has-status" :class="{['is-' + internalStatus]: internalStatus !== 'default'}">
		<template #icon><slot name="icon" /></template>

		<slot />

		<template #icon2><slot name="icon2" /></template>

		<template #status-icon>
			<i-cog v-if="internalStatus === 'validating' || internalStatus === 'authenticating'" class="cog-button__status-icon is-validating" />
			<i-check v-else-if="internalStatus === 'success'" class="cog-button__status-icon is-success" />
			<i-error v-else-if="internalStatus === 'error'" class="cog-button__status-icon is-error" />
		</template>
	</c-button>
</template>

<script>
	import '../style/_motion.scss';

	import CButton from './Button.vue';
	import ICog from '../assets/cog.svg';
	import IError from '../assets/error-outline.svg';
	import ICheck from '../assets/check-outline.svg';
	import { FormStatus, FormStatusProp } from 'src/mixins/form-status';

	export default {
		name: 'CStatusButton',
		components: { CButton, ICog, IError, ICheck },
		props: {
			status: FormStatusProp,
			duration: {
				default: 1000,
				type: Number
			}
		},
		data() {
			return {
				internalStatus: FormStatus.Default
			};
		},
		watch: {
			status(newStatus, oldStatus) {
				this.internalStatus = newStatus;

				// InProgress is the only status that should not automatically dissipate
				if (newStatus !== FormStatus.InProgress && newStatus !== FormStatus.Authenticating) {
					setTimeout(() => {
						this.internalStatus = FormStatus.Default;
					}, this.duration);
				}
			}
		}
	};
</script>

<style lang="scss">
#{$specificity-base} {

	.cog-button__status-icon {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		margin: auto;
	}

	.cog-button--has-status[class*='is-'] .cog-button__text,
	.cog-button--has-status[class*='is-'] .cog-button__icon {
		opacity: .3;
	}

	// In Progress
	.cog-button__status-icon.is-validating {
		animation: cog-spin calc(var(--speed) * 1.5) infinite linear;
		fill: currentcolor;
		stroke: transparent;
	}

	// Success
	.cog-button__status-icon.is-success {
		animation: cog-tada var(--speed);
	}

	// Error
	.cog-button.is-error {
		animation: cog-shake var(--speed);
	}

	.cog-button__status-icon.is-error {
		transform: scale(1.3);
	}
}
</style>