import type { EntrySaveResult, ResumeEntryResult, EntrySubmissionResult } from './entry-service';
import { EntryService, parseStoreResult } from './entry-service';
import type { NotificationAddress } from '@cognitoforms/types/server-types/model/notification-address';
import type { FormEntry } from '@cognitoforms/types/server-types/forms/model/form-entry';
import type { FormSession } from './form-session';
import type { Order } from '@cognitoforms/types/server-types/payment/model/order';

export class PreviewEntryService extends EntryService {
	readonly formJson: string;

	constructor(session: FormSession, formJson: string) {
		super(session);
		this.formJson = formJson;
	}

	async submit(entry: FormEntry, embedUrl: string, entryToken: string): Promise<EntrySubmissionResult> {
		const entryJson = entry.serialize();
		const order = entry.Order as Order;
		const result = await this.serviceRequest({
			method: 'post',
			endpoint: `forms/${this.session.isAnonymous ? 'anonymous' : 'admin'}/submitpreviewentry`,
			headers: { [this.session.botValidation.method]: this.session.botValidation.token },
			data: {
				formJSON: encodeURIComponent(this.formJson),
				entryJSON: JSON.stringify(entryJson),
				orderJSON: order ? JSON.stringify(order.serialize()) : null
			}
		});

		if (result.response && result.response.data) {
			const data = result.response.data;
			const submissionResult = parseStoreResult(data) as EntrySubmissionResult;

			// Add the session token to document links to support downloads
			if (data.entryDocuments) {
				submissionResult.documents = data.entryDocuments.map(doc => {
					doc.link = doc.link + '&sessionToken=' + encodeURIComponent(this.sessionToken);
					return doc;
				});
			}

			return submissionResult;
		}

		throw this.parseError(result.error);
	}

	async save(entry: FormEntry, embedUrl: string, entryToken: string, resumePage?: number): Promise<EntrySaveResult> {
		const saveAndResumeAddresses = [];

		try {
			const saveAndResumeRecipients = JSON.parse(this.formJson).SaveAndResumeNotification.Recipients as NotificationAddress[];
			saveAndResumeRecipients
				.map(email => email.Address)
				.forEach(emailAddress => {
					// Retrieve the email address from the entry if the reference is a token
					if (emailAddress && emailAddress.indexOf('@') < 0)
						emailAddress = entry.toString(emailAddress);

					if (emailAddress)
						saveAndResumeAddresses.push(emailAddress);
				});
		}
		catch (e) { }

		const data = {
			emailAddress: saveAndResumeAddresses.join('; '),
			emailMessage: '',
			link: 'This is where your link will go.'
		} as EntrySaveResult;

		const saveResult = parseStoreResult(data) as unknown as EntrySaveResult;
		saveResult.emailAddress = data.emailAddress;
		saveResult.emailMessage = data.emailMessage;
		saveResult.link = data.link;
		saveResult.enableSend = false;

		return saveResult;
	}

	emailResumeLink(entryId: string, recipient: string, message: string, link: string) {
		// noop
	}

	async resume(formId: string, entryToken: string): Promise<ResumeEntryResult> {
		throw new Error('Resuming an entry is not applicable in preview.');
	}
}