import type { EntrySubmissionResult, EntrySaveResult, ResumeEntryResult } from './entry-service';
import { EntryService, parseStoreResult } from './entry-service';
import type { FormEntry } from '@cognitoforms/types/server-types/forms/model/form-entry';
import type { Order } from '@cognitoforms/types/server-types/payment/model/order';
import type { EntityOfType } from '@cognitoforms/model.js';
import type { FormEntryWithOptionalOrder } from 'src/framework/model/extensions/form-entry-extensions';

export class EntriesEntryService extends EntryService {
	async submit(entry: EntityOfType<FormEntryWithOptionalOrder>, embedUrl: string): Promise<EntrySubmissionResult> {
		const entryJson = entry.serialize();
		const order = entry.Order as Order;
		const result = await this.serviceRequest({
			method: 'post',
			endpoint: 'forms/admin/entry',
			isCognitoJson: true,
			data: {
				Entry: entryJson,
				OrderAmount: order ? order.OrderAmount : null,
				AccessToken: this.session.accessToken,
				SaveAndResume_EmbedUrl: embedUrl
			}
		});

		// TODO: Return data in the same shape it comes from the server?
		if (result.response && result.response.data) {
			const data = result.response.data;
			return parseStoreResult(data) as EntrySubmissionResult;
		}

		throw this.parseError(result.error);
	}

	async save(entry: EntityOfType<FormEntryWithOptionalOrder>, embedUrl: string, entryToken: string, resumePage?: number): Promise<EntrySaveResult> {
		const data = await this.serviceRequest({
			method: 'post',
			endpoint: 'forms/admin/saveincompleteentry',
			isCognitoJson: true,
			data: {
				Entry: entry.serialize(),
				OrderAmount: entry.Order ? entry.Order.OrderAmount : null,
				AccessToken: this.session.accessToken
			}
		});

		return parseStoreResult(data.response.data) as unknown as EntrySaveResult;
	}

	async emailResumeLink(entryId: string, recipient: string, message: string, link: string) {
		throw new Error('Can not email a save & resume link on the entries page.');
	}

	async resume(formId: string, entryToken: string): Promise<ResumeEntryResult> {
		throw new Error('Can not resume an entry on the entries page.');
	}

	async getEntry(entryId: string) {
		const result = await this.serviceRequest({
			method: 'GET',
			endpoint: 'forms/admin/entry',
			params: { id: entryId }
		});

		return result.response.data as FormEntry;
	}
}