var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.render
    ? _c(
        _vm.fieldContainerType,
        {
          ref: "field",
          tag: "component",
          class: {
            "cog-field": !_vm.isTable,
            "is-focus": _vm.isFocused,
            ["cog-field--" + _vm.fieldIndex]: _vm.fieldIndex,
            ["cog-col " + _vm.colNumClass]: !_vm.isTable,
            ["cog-" + _vm.type]: _vm.type,
            [_vm.subtypeClass]: _vm.subtype,
            [_vm.customClass]: _vm.customClass,
            "is-error": _vm.showValidation && !_vm.containerOnly,
            "is-required": _vm.$source.required,
            "cog-hidden-validation": _vm.validationOnly,
            "cog-force-shown": _vm.forceShown
          },
          attrs: { "aria-hidden": _vm.containerOnly }
        },
        [
          !_vm.validationOnly && !_vm.containerOnly
            ? [
                !_vm.isTable
                  ? _c(
                      _vm.labelType,
                      {
                        tag: "label",
                        staticClass: "cog-label",
                        class: { "cog-offscreen": !_vm.showLabel },
                        attrs: {
                          id:
                            _vm.labelType === "label"
                              ? `cog-${_vm.$source.id}-label`
                              : false,
                          for:
                            _vm.labelType === "label"
                              ? "cog-" + _vm.$source.id
                              : false
                        }
                      },
                      [
                        _vm._v(
                          "\n\t\t\t" +
                            _vm._s(_vm._f("breakWords")(_vm.$source.label))
                        ),
                        _vm.showAsterisk
                          ? _c(
                              "span",
                              {
                                staticClass: "cog-asterisk",
                                attrs: { "aria-hidden": "true" }
                              },
                              [_vm._v("*")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showAsterisk && !_vm.showValidation
                          ? _c("span", { staticClass: "cog-offscreen" }, [
                              _vm._v(
                                "(" +
                                  _vm._s(_vm.$resource("required-asterisk")) +
                                  ")"
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.showValidation
                          ? _c("span", { staticClass: "cog-offscreen" }, [
                              _vm._v("(" + _vm._s(_vm.firstError.message) + ")")
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("default", null, {
                  field: _vm.$source,
                  isTable: _vm.isTable,
                  focusLost: _vm.handleFocusLost
                }),
                _vm._v(" "),
                !_vm.isTable &&
                (_vm.subtype !== "checkboxes" || _vm.subtype !== "radiobuttons")
                  ? _c(
                      "div",
                      { staticClass: "cog-field__placeholder cog-input" },
                      [
                        _c(
                          "div",
                          { staticClass: "cog-field__placeholder-inner" },
                          [_vm._v("\n\t\t\t\t \n\t\t\t")]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isTable && _vm.$source.helptext && !_vm.$source.readonly
                  ? _c("div", {
                      staticClass: "cog-helptext cog-html",
                      domProps: { innerHTML: _vm._s(_vm.$source.helptext) }
                    })
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.containerOnly
            ? _c("transition", { attrs: { name: "cog-error-message" } }, [
                !_vm.duplicateError && _vm.showValidation
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "cog-error-message cog-error-message--container-footer"
                      },
                      [_vm._v(_vm._s(_vm.firstError.message))]
                    )
                  : _vm._e()
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isTable &&
          _vm.showValidation &&
          !_vm.containerOnly &&
          (!_vm.$source.readonly || _vm.isQuantityLimitError(_vm.firstError))
            ? _c(
                "span",
                {
                  staticClass: "cog-error-message__icon-container",
                  attrs: { title: _vm.firstError && _vm.firstError.message }
                },
                [_c("i-error", { staticClass: "cog-error-message__icon" })],
                1
              )
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }