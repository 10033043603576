<template>
	<el-button :class="noStyle ? 'cog-button--no-style' : 'cog-button'" @click="handleClick">
		<span v-if="$slots.icon" class="cog-button__icon"><slot name="icon" /></span>
		<span v-if="!noStyle && hasText" class="cog-button__text"><slot></slot></span>
		<slot v-else />
		<span v-if="$slots.icon2" class="cog-button__icon"><slot name="icon2" /></span>
		<template><slot name="status-icon" /></template>
	</el-button>
</template>

<script>
	import Vue from 'vue';
	import { Button } from '@cognitoforms/element-ui';
	Vue.use(Button);

	export default {
		name: 'CButton',
		props: {
			noStyle: {
				default: false,
				type: Boolean
			}
		},
		computed: {
			hasText() {
				return this.$slots.default[0].text ? this.$slots.default[0].text.trim().length : false;
			}
		},
		methods: {
			handleClick: function (event) {
				this.$emit('click', event);
			}
		}
	};
</script>

<style lang="scss">
#{$specificity-base} {

	.cog-button--no-style {
		@include no-style-button;
	}

	.cog-button svg {
		transition: $speed-quarter;
	}

	// override Safari's added margins
	.cog-button {
		margin: 0;
	}

	.cog-cognito--styled {

		.cog-button--no-style {
			color: var(--color);
		}

		.cog-button {
			position: relative;
			font-size: var(--button-primary__font-size, inherit);
			font-weight: var(--button-primary__font-weight, normal);
			line-height: calc(var(--line-height) - .1);
			transition: $speed-quarter;

			&:focus {
				border-width: var(--border-width);
				border-style: solid;
				border-color: var(--form__background-color);
				box-shadow: 0 0 0 var(--border-width) var(--highlight);
			}

			& > span {
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
			}
		}

		// Moste of the padding comes from the button itself, but some padding surrounds these
		// elements so that if they ever become stacked (in tight spaces) the spacing works out.
		.cog-button__icon,
		.cog-button__text {
			padding: calc(var(--input__padding-v) * .25) calc(var(--input__padding-h) * .1);
		}

		.cog-button__icon svg {
			width: 1.7em;
			height: 1.7em;
			font-size: .8em;
			stroke-width: calc(var(--icon-weight) * 1.1px);
		}

		// Primary Buttons: Selected button background and text color. E.g submit button
		// Secondary Buttons: Colors are reversed from primary buttons. They are smaller except for buttons in bottom navigation.

		.cog-button--primary {
			background: var(--button-primary__background-color, var(--primary));
			border: var(--button-primary__border-width) var(--button-primary__border-color, var(--primary)) solid;
			border-radius: var(--button-primary__border-radius);
			color: var(--button-primary__color, var(--primary-reverse));
			-webkit-print-color-adjust: exact;
			color-adjust: exact;
			font-family: var(--button-primary__font-family, inherit);

			&:hover {
				border-color: var(--button-primary__color, var(--primary-reverse));
			}
		}

		.cog-button--primary,
		.cog-page__navigation .cog-button {
			min-width: 80px;
			padding: calc(var(--input__padding-v) * .75) calc(var(--input__padding-h) * .75);
		}

		.cog-button--secondary {
			min-width: 55px;
			background: var(--button-secondary__background-color, var(--primary-reverse));
			border: var(--button-secondary__border-width) var(--button-secondary__border-color, var(--primary)) solid;
			border-radius: var(--button-secondary__border-radius);
			padding: calc(var(--input__padding-v) * .5) calc(var(--input__padding-h) * .7);
			color: var(--button-secondary__color, var(--primary));
			font-family: var(--button-secondary__font-family, inherit);

			&:hover {
				box-shadow: inset 0 0 0 1px var(--button-secondary__color, var(--primary));
			}
		}

		.cog-button--no-text {
			min-width: 0;
		}

		.cog-button[disabled],
		.cog-button--no-style[disabled] {
			cursor: not-allowed;
			opacity: .6;
		}
	}
}
</style>
