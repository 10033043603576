import type FormBase from './FormBase';
import { FormEvents } from 'src/framework/public/events';
import { FormEvent } from 'src/framework/eventing/form-event';
import ResizeObserver from 'resize-observer-polyfill';
import type { FormEventScope } from 'src/framework/eventing/form-event-scope';
import type DiffViewer from './DiffViewer';

export function ensureMinHeight(publicEvents: FormEventScope, minHeight: number) {
	if (!window.name.includes('amp_iframe'))
		publicEvents.emit(new FormEvent(FormEvents.Resize, {
			minHeight
		}));
}

export function emitResizeEvent(form: FormBase|DiffViewer, height?: number) {
	if (!window.name.includes('amp_iframe')) {
		form.publicEvents.emit(new FormEvent(FormEvents.Resize, {
			height: height,
			isDelta: false
		}));
	}
	else
		window.parent.postMessage(
			{
				sentinel: 'amp',
				type: 'embed-size',
				height: height
			},
			'*'
		);
}

export function enableResizeDetection(form: FormBase | DiffViewer) {
	const resizeObserver = new ResizeObserver((entries) => {
		const { height } = entries[0].contentRect;

		// The different rounding fixes #20816 for different pixel desnsities
		if (window.devicePixelRatio % 1) {
			const DVPDecimal:number = +(window.devicePixelRatio % 1).toFixed(2);
			const heightMultiplier = DVPDecimal > .5 ? 1 - DVPDecimal : DVPDecimal;
			emitResizeEvent(form, Math.round(height * heightMultiplier) / heightMultiplier);
		}
		else {
			emitResizeEvent(form, Math.round(height));
		}
	});

	resizeObserver.observe(form.$el);
}