import { BaseService } from './base-service';
import { parseUrlHostname } from 'src/util';
import type { FormSessionData, FormSessionFlagsData } from './form-session';
import { FormSession } from './form-session';
import { EmbedContext } from 'src/framework/public/form-handle';
import type { ObjectLookup } from '@cognitoforms/model.js/@types/helpers';
import { CustomResponseError } from './custom-response-error';
import { getSiteUrl } from 'src/util/site-url';

function createSessionForDisabledForm(formStatus: 'Disabled' | 'AutoDisabled'): FormSessionData {
	return {
		formStatus,
		token: null,
		botChallenge: { chars: [], map: [] },
		paymentApiKey: null,
		paymentLocationId: null,
		paymentLocationCurrencyCode: null,
		paymentSdkUrl: null,
		accessToken: null,
		organizationId: null,
		flags: {},
		fileUploadLimit: 0,
		encryptionKey: '',
		viewTokens: null
	} as FormSessionData;
}

function pascalToCamelCase(pascalStr: string) {
	if (!pascalStr || !pascalStr.trim())
		return '';
	return pascalStr[0].toLowerCase() + pascalStr.substr(1);
}

function ensureCamelCaseFlags(flags: ObjectLookup<boolean>): FormSessionFlagsData {
	const camelCaseFlags: FormSessionFlagsData = {};
	for (const flag in flags)
		camelCaseFlags[pascalToCamelCase(flag)] = flags[flag];
	return camelCaseFlags;
}

const cognitoHostName = parseUrlHostname(getSiteUrl());

export class SessionService extends BaseService {
	// readonly sessions = new Map<string, string>();

	async newSession(formId: string, embedContext: EmbedContext = EmbedContext.Public, formJson?: string, entryToken?: string): Promise<FormSession> {
		const isAdminSession = embedContext === EmbedContext.Publish || embedContext === EmbedContext.Preview || embedContext === EmbedContext.Entries || embedContext === EmbedContext.FormView;
		const request = embedContext === EmbedContext.Preview
			? this.serviceRequest({
				method: 'post',
				endpoint: 'svc/load-form/new-preview-session',
				data: { formJson }
			})
			: this.serviceRequest({
				method: 'post',
				endpoint: isAdminSession
					? `svc/load-form/new-admin-session/${formId}`
					: `svc/load-form/new-session/${this.apiKey}/${formId}`,
				params: { embedContext, entryToken }
			});

		const sessionData = await request.then(res => {
			if (res.error) {
				// Check for a failure response that returns a partial/invalid session object (i.e. disabled form)
				if (res.error instanceof CustomResponseError && res.error.type === 'FormDisabled')
					return createSessionForDisabledForm(res.error.data.FormStatus);

				throw res.error;
			}
			else {
				return res.response.data;
			}
		});

		sessionData.flags = ensureCamelCaseFlags(sessionData.flags);

		return new FormSession(Object.assign({ formId, apiKey: this.apiKey, cognitoHostName }, sessionData));
	}
}