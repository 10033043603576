export function harvestCssVars(cssVars: string) {
	const regex = /\{(.+?)\}/;

	return regex.exec(cssVars.replace(/https:/g, 'https__COLON__'))[1] // 1 corresponds to the first match group
		.split(';')
		.slice(0, -1)
		.map(declaration => declaration.split(':').map(part => part.trim()))
		.reduce((map, [property, value]) => {
			map[property] = value.replace('https__COLON__', 'https:');
			return map;
		}, {});
}
