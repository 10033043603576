import { isEntity, isEntityType } from '@cognitoforms/model.js';
import VueModel from '@cognitoforms/vuemodel';

export default {
	mixins: [VueModel.mixins.SourcePath],
	inject: {
		form: {},
		pageNumber: { default: 1 }
	},
	props: {
		// If a validation filter is specified, then the list of validation conditions considered will be filtered to those
		// where the ConditionType's code matches the expression, ex: /.QuantityValidation$/ will only include quantity limit errors
		validationFilter: {
			type: RegExp,
			required: false,
			default: null
		}
	},
	data() {
		return {
			isDirty: false,
			lostFocus: false
		};
	},
	mounted() {
		if (this.form)
			this.form.registerField(this);

		// The $source.value watcher that marks this component as dirty does not trigger when updating entity sub fields (Address.City, Name.First, etc.)
		this.detectDirtyEntity();

		this.$el.addEventListener('focusout', evt => {
			const elGainingFocus = evt.relatedTarget;
			// Allow focus to be in limbo for just a bit before considering it lost. Cf. Select.vue
			const lostFocusDelay = 50;
			if (this.isDirty && (!elGainingFocus || !this.$el.contains(elGainingFocus)))
				setTimeout(() => {
					if (!this.$el.contains(document.activeElement))
						this.lostFocus = true;
				}, lostFocusDelay);
		});
	},
	beforeDestroy() {
		if (this.form)
			this.form.unregisterField(this);
	},
	created() {
		if (this.firstError) {
			// Trigger the error change handler when the component is first created in order to mark quantity limit errors as dirty.
			// The watcher may not fire in some cases, for example when switching between table and responsive section mode.
			this.handleErrorChange(this.firstError);
		}
	},
	watch: {
		'$source.value': function() {
			this.isDirty = true;
		},
		firstError: function(err) {
			this.handleErrorChange(err);
		}
	},
	computed: {
		showValidation() {
			if (!this.form.flags.workflowEnabled && this.form.readonly)
				return false;

			return (this.isDirty && this.lostFocus && this.hasError) || this.hasFormatError;
		},
		hasError() {
			return !!this.firstError && !this.ignoreError;
		},
		conditions() {
			if (this.validationFilter)
				return this.$source.conditions.filter(c => this.validationFilter.test(c.type.code));
			else
				return this.$source.conditions;
		},
		firstError() {
			if (!this.$source.firstError)
				return null;

			const workflowIgnoreQuantityError = this.form.flags.workflowEnabled && this.form.entry.Next_Status === 'Incomplete';
			const conditions = this.conditions.filter(c => !workflowIgnoreQuantityError || !this.isQuantityLimitError(c));

			const quantityError = conditions.find(this.isQuantityLimitError);
			if (quantityError)
				return quantityError;
			else
				return conditions.length ? conditions[0] : null;
		},
		hasFormatError() {
			return this.firstError && this.firstError.formatError;
		}
	},
	methods: {
		detectDirtyEntity() {
			if (this.$source.property && isEntityType(this.$source.property.propertyType) && this.$source.value) {
				const markDirtyForTrueChange = e => {
					/**
					 * Initialization of entity sub properties is triggering change on the entity.
					 * Use existence of oldValue to determine if it is a true change, or just init.
					 * Ex: Initialization of Name.First (undefined -> null) triggers change on the Name entity
					 */
					if (e.oldValue !== undefined) {
						this.isDirty = true;
						// to ensure the subscription is removed, check the old and current value
						if (isEntity(e.oldValue))
							e.oldValue.changed.unsubscribe(markDirtyForTrueChange);
						if (isEntity(this.$source.value))
							this.$source.value.changed.unsubscribe(markDirtyForTrueChange);
					}
				};
				this.$source.value.changed.subscribe(markDirtyForTrueChange);
			}
		},
		handleErrorChange(err) {
			// mark this field as having been modified by the user to allow showing quantity validation
			if (this.isQuantityLimitError(err)) {
				this.isDirty = true;
				this.lostFocus = true;
			}
		},
		isQuantityLimitError(err) {
			return err && err.type.code.endsWith('.QuantityValidation');
		},
		forceValidation() {
			this.isDirty = true;
			this.lostFocus = true;
		}
	}
};