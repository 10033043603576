var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.toggleRecaptcha,
          expression: "toggleRecaptcha"
        }
      ],
      staticClass: "cog-row"
    },
    [
      _c(
        "div",
        { staticClass: "cog-field cog-col cog-col--12" },
        [
          _vm.toggleRecaptcha
            ? _c("vue-recaptcha", {
                ref: "recaptcha",
                attrs: {
                  id: "cog-recaptcha",
                  sitekey: "6Le2FjcUAAAAAEr2XptT-aju23v2AMoHOR18ixHA",
                  "load-recaptcha-script": _vm.loadRecaptchaScript
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }