import { ServiceWithSession } from './base-service';
import type { FormsModelOptionsGeneratorFunction, FormDefinitionTheme } from 'src/framework/form-definition';
import FormDefinition from 'src/framework/form-definition';
import { EmbedContext } from 'src/framework/public/form-handle';

export type FormScriptDefinition = {
	template: string;
	modelOptions: FormsModelOptionsGeneratorFunction;
	theme: FormDefinitionTheme;
	id?: string;
};

export class FormService extends ServiceWithSession {
	get formId(): string {
		return this.session.formId;
	}

	/**
	 * Loads the information defining a form by injecting a script tag, which, when executed
	 * will place the form definition object on the script's DOM node. If the form definition
	 * script has already been loaded, a web request is not made.
	 * @param formId The form for which to fetch the definition.
	 */
	async getDefinition(embedContext: EmbedContext): Promise<FormDefinition> {
		// AxiosInstance has a getUri method that is supposed to do this, but it is bugged
		const src = this.getUri({ url: `svc/load-form/form-def/${this.apiKey}/${this.formId}` });
		const query = embedContext === EmbedContext.Preview ? 'script.preview-form-definition' : `script[src='${src}']`;
		let formDef: FormScriptDefinition = null;
		let script = null;

		try {
			script = document.querySelector(query) as HTMLScriptElement;
			const getFormDef = (script: HTMLScriptElement) => script['formDefinition'] as FormScriptDefinition;
			if (script)
				formDef = getFormDef(script);
			else {
				formDef = await (new Promise((resolve, reject) => {
					script = document.createElement('script');
					script.src = src;
					script.onload = () => resolve(getFormDef(script));
					script.onerror = e => reject(new Error(e.toString()));
					document.head.appendChild(script);
				}));
			}
		}
		catch (e) {
			if (!formDef)
				return null;
		}

		const formInternalName = script ? script.getAttribute('data-form-internal-name') : null;

		return FormDefinition.create(
			this.formId,
			formInternalName,
			script,
			formDef.template,
			formDef.modelOptions,
			formDef.theme,
			this.session.formUtcOffset);
	}

	async getPreviewDefinition(formJson: string): Promise<FormDefinition> {
		let script: HTMLScriptElement;
		let formDef: FormScriptDefinition = null;

		try {
			const request = this.serviceRequest({
				method: 'POST',
				endpoint: 'svc/preview-form/form-def',
				data: { formJson }
			});

			const src = await request.then(res => {
				if (res.error) {
					throw res.error;
				}
				else {
					return res.response.data;
				}
			});

			script = document.createElement('script');
			script.innerText = src;
			document.head.appendChild(script);
			formDef = script['formDefinition'] as FormScriptDefinition;
		}
		catch (e) {
			if (!formDef)
				return null;
		}

		const formInternalName = JSON.parse(formJson).InternalName;

		return FormDefinition.create(
			this.formId,
			formInternalName,
			script,
			formDef.template,
			formDef.modelOptions,
			formDef.theme,
			this.session.formUtcOffset);
	}

	reportLoadingError(formId: string, errorMessage?: string) {
		return this.serviceRequest({
			endpoint: `svc/load-form/error/${this.apiKey}/${formId}`,
			data: { errorMessage },
			method: 'post'
		});
	}
}