import type { LocalizedResources } from '@cognitoforms/model.js';
import type { UserInfo } from '@cognitoforms/types/server-types/forms/model/user-info';
import type { PaymentProcessor } from '@cognitoforms/types/server-types/payment/model/payment-processor';

export type BotChallengeData = {
	map: number[];
	chars: string[];
}

export enum BotValidationMethod {
	NoBots = 'X-NoBots',
	Recaptcha = 'X-Recaptcha'
}

export type BotValidation = {
	method: BotValidationMethod;
	token: string;
}

export type EntityStatus = 'Enabled' | 'Disabled' | 'AutoDisabled';

export type FormSessionData = {
	isReadonly?: boolean;
	formId?: string;
	apiKey?: string;
	formStatus: EntityStatus;
	token?: string;
	botChallenge: BotChallengeData;
	paymentProcessor: PaymentProcessor;
	paymentTestMode?: boolean;
	paymentApiKey?: string;
	paymentLocationId?: string;
	paymentLocationCurrencyCode?: string;
	paymentSdkUrl?: string;
	accessToken?: string;
	organizationId?: string;
	flags?: FormSessionFlagsData;
	fileUploadLimit?: number;
	encryptionKey?: string;
	viewTokens?: { [key: string]: string };
	publicRole?: string;
	paymentIntentClientSecret?: string;
	userInfo?: object;
	addressAutocompleteKey?: string;
};

export type FormSessionFlagsData = {
	entryCaching?: boolean;
	embedded?: boolean;
	saveAndResume?: boolean;
	lookupField?: boolean;
	signatureField?: boolean;
	tableField?: boolean;
	passwordField?: boolean;
	formTracking?: boolean;
	iframeFormTracking?: boolean;
	branding?: boolean;
	minimalBranding?: boolean;
	abuseLink?: boolean;
	hideHeader?: boolean;
	encrypt?: boolean;
	internal?: boolean;
	mobile?: boolean;
	hasLookups?: boolean;
	hasQuantityLimits?: boolean;
	payment?: boolean;
	available?: boolean;
	archived?: boolean;
	square?: boolean;
	paypal?: boolean;
	saveCustomerCard?: boolean;
	log?: boolean;
	redirect?: boolean;
	showUsersRedirected?: boolean;
	paging?: boolean;
	submissionSettings?: boolean;
	showSubmissionWarning?: boolean;
	blockSubmission?: boolean;
	conditionalVisibility?: boolean;
	workflowEnabled?: boolean;
	disableConfirmationLinks?: boolean;
	allowExternalLinks?: boolean;
	processPayment?: boolean;
	simulatePayment?: boolean;
	plaintextPasswords?: boolean,
	autoScroll?: boolean,
	forceHideOrder?: boolean,
	useNativeScrolling?: boolean,
	disableValidation?: boolean,
	forceLightBackgroundClass?: boolean,
	requireAuthenticationEmbedded?: boolean,
	modelBasedValidation?: boolean,
	fileDownloadsDisabled?: boolean,
	offloadSignatureData?: boolean;
	addressAutocomplete?: boolean;
};

export class FormSession {
	private _botChallenge: BotChallengeData;
	private resources: LocalizedResources;
	readonly token: string;
	readonly apiKey: string;
	readonly organizationId: string;
	readonly isReadonly: boolean;
	accessToken: string;
	readonly paymentProcessor: PaymentProcessor;
	readonly paymentTestMode: boolean;
	readonly paymentApiKey: string;
	readonly paymentLocationId: string;
	readonly paymentLocationCurrencyCode: string;
	readonly paymentSdkUrl: string;
	readonly paymentProcessorTrackingId: string;
	readonly paymentClientToken: string;
	readonly paymentMerchantId: string;
	readonly formId: string;
	readonly formStatus: EntityStatus;
	readonly flags: FormSessionFlagsData;
	readonly fileUploadLimit: number;
	readonly viewTokens: Readonly<Record<string, string>>;
	readonly encryptionKey: string;
	readonly isAnonymous: boolean;
	botValidation: BotValidation;
	readonly cognitoHostName: string;
	isValidSharedLink: boolean = true;
	readonly publicRole: string;
	readonly userInfo: UserInfo;
	readonly formUrl: string;
	readonly linkText: string;
	readonly formUtcOffset: number;
	addressAutocompleteKey: string;

	constructor(sessionData: FormSessionData) {
		// Initialize mutable properties
		this._botChallenge = sessionData.botChallenge;
		delete sessionData.botChallenge;

		this.accessToken = sessionData.accessToken;
		delete sessionData.accessToken;

		// Initialize immutable properties
		for (const p in sessionData) {
			const value = sessionData[p];
			Object.defineProperty(this, p, {
				enumerable: true,
				writable: false,
				value: value && typeof value === 'object' ? Object.freeze(value) : value
			});
		}

		this.isAnonymous = sessionData.apiKey === '00000000-0000-0000-0000-000000000000';
	}

	get botChallenge() {
		return this._botChallenge?.map.map(i => this._botChallenge.chars[i]).join('');
	}

	isHuman(evidence: BotValidation) {
		this.botValidation = evidence;
	}

	registerResources(resources: LocalizedResources) {
		this.resources = resources;
	}

	getResource(resource: string) {
		return this.resources ? this.resources[resource] : '';
	}
}
