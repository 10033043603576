var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.virtual
    ? _c(
        _vm.paging ? "transition" : "visible-transition",
        _vm._g(
          _vm._b(
            { tag: "component" },
            "component",
            _vm.paging
              ? {
                  name: "cog-page"
                }
              : null,
            false
          ),
          _vm.paging
            ? {
                "before-enter": _vm.beforeEnter,
                enter: _vm.onEnter,
                "after-enter": _vm.afterEnter
              }
            : null
        ),
        [
          _vm.current && _vm.canRender
            ? _c(
                "div",
                {
                  ref: "page",
                  staticClass: "cog-page cog-wrapper",
                  class: "cog-transition-" + _vm.transition,
                  attrs: {
                    "data-page":
                      _vm.hideNumber ||
                      (_vm.isSubmission && _vm.visibleNumber === 1)
                        ? false
                        : _vm.visibleNumber
                  }
                },
                [
                  _vm._t("default"),
                  _vm._v(" "),
                  (_vm.isSubmission || _vm.showSaveButton) &&
                  !_vm.session.botValidation &&
                  _vm.session.botChallenge
                    ? _c("Captcha", {
                        ref: "captcha",
                        attrs: { "bot-challenge": _vm.session.botChallenge },
                        on: { validated: _vm.captchaValidated }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.showNavigation && _vm.hasButtons && _vm.paging) ||
                  (_vm.showNavigation && _vm.hasButtons && _vm.isSubmission)
                    ? _c(
                        "div",
                        {
                          ref: "navigation",
                          staticClass: "cog-page__navigation",
                          class: {
                            "cog-page__navigation--condensed":
                              _vm.navIsCondensed,
                            "is-being-measured": _vm.measuringIfNavIsCondensed
                          }
                        },
                        [
                          _vm.showBackButton
                            ? _c(
                                "c-button",
                                {
                                  staticClass:
                                    "cog-button--secondary cog-button--navigation cog-button--back",
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.$emit("back")
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "icon",
                                        fn: function() {
                                          return [_c("i-back")]
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    false,
                                    1215831692
                                  )
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t" +
                                      _vm._s(_vm.avoidOrphans(_vm.backButton)) +
                                      "\n\t\t\t"
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isSubmission && _vm.flags.workflowEnabled
                            ? _vm._l(_vm.allowedActions, function(action) {
                                return _c(
                                  "c-status-button",
                                  {
                                    key: action.ActionName,
                                    staticClass:
                                      "cog-button--primary cog-button--navigation cog-button--submit",
                                    attrs: {
                                      "data-next": "",
                                      "data-action-name": action.ActionName,
                                      type: "submit",
                                      status: _vm.getActionStatus(action)
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return _vm.handleSubmitClick.apply(
                                          null,
                                          arguments
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.avoidOrphans(action.ButtonText)
                                        ) +
                                        "\n\t\t\t\t"
                                    )
                                  ]
                                )
                              })
                            : _c(
                                "c-status-button",
                                {
                                  staticClass:
                                    "cog-button--primary cog-button--navigation",
                                  class: {
                                    "cog-button--submit": _vm.isSubmission,
                                    "cog-button--next": !_vm.isSubmission
                                  },
                                  attrs: {
                                    "data-next": "",
                                    type: _vm.nextButtonType,
                                    status: _vm.submitStatus
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.isSubmission
                                        ? _vm.handleSubmitClick($event)
                                        : _vm.handleNext()
                                    }
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      !_vm.isSubmission
                                        ? {
                                            key: "icon2",
                                            fn: function() {
                                              return [_c("i-next")]
                                            },
                                            proxy: true
                                          }
                                        : null
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _vm._v(
                                    "\n\t\t\t\t" +
                                      _vm._s(
                                        _vm.isSubmission
                                          ? _vm.avoidOrphans(
                                              _vm.submitButtonText
                                            )
                                          : _vm.avoidOrphans(_vm.nextButton)
                                      ) +
                                      "\n\t\t\t"
                                  )
                                ]
                              ),
                          _vm._v(" "),
                          _vm.showSaveButton
                            ? _c(
                                "c-status-button",
                                {
                                  staticClass:
                                    "cog-button--save cog-button--secondary cog-button--navigation",
                                  attrs: { status: _vm.saveStatus },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.handleSave.apply(
                                        null,
                                        arguments
                                      )
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.saveButtonText))]
                              )
                            : _vm._e()
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.showSubmissionWarning && _vm.isSubmission
                    ? _c("div", { staticClass: "cog-submission-warning" }, [
                        _vm._v(
                          _vm._s(_vm.$resource("public-submission-warning"))
                        )
                      ])
                    : _vm._e()
                ],
                2
              )
            : _vm._e()
        ]
      )
    : _c(
        "div",
        { staticClass: "hide" },
        [_vm._t("default", null, { virtual: _vm.virtual })],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }