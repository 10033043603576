// NOTE: Generated by `generate-localizations.ps1`

const productSupportedLocales: { [code: string]: string } = {
	en: 'English',
	hu: 'Hungarian',
	th: 'Thai',
	sv: 'Swedish',
	es: 'Spanish',
	hi: 'Hindi',
	nl: 'Dutch',
	fi: 'Finnish',
	bg: 'Bulgarian',
	zh: 'Chinese',
	it: 'Italian',
	pt: 'Portuguese',
	nb: 'Norwegian (Bokmål)',
	pl: 'Polish',
	fr: 'French',
	ja: 'Japanese',
	el: 'Greek',
	da: 'Danish',
	tr: 'Turkish',
	de: 'German',
	hr: 'Croatian'
};

export default productSupportedLocales;
