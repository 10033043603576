import { render, staticRenderFns } from "./Choice.vue?vue&type=template&id=8e1a05a2&"
import script from "./Choice.vue?vue&type=script&lang=js&"
export * from "./Choice.vue?vue&type=script&lang=js&"
import style0 from "./Choice.vue?vue&type=style&index=0&id=8e1a05a2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.10.0_babel-core@7.0.0-bridge.0_css-loader@3.2.0_lodash@4.17.21_vue-template-compiler@2.7.15_webpack@4.46.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports