<template>
	<div class="cog-row">
		<slot></slot>
	</div>
</template>

<script>
	export default {
		name: 'CRow'
	};
</script>
