import scrollIntoView from 'src/util/scroll-into-view';

export default {
	inject: {
		form: {
			from: 'form',
			default: null
		}
	},
	props: {

		duration: {
			type: Number,
			default: 500
		},
		scroll: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		// Initiates the show transition for the specified element
		beforeEnter: function (el) {
			if (this.form && this.form.disableTransitions)
				return;
			// For slow connections
			// 1. Moderately fast connections (~1MB/s) will never see the placeholder
			// 2. Slow connections (Fast 3G and worse) will see the placeholder pop in and then transition to the field when it loads

			// We could hide the placeholder altogether to avoid the jarring pop-in, but the long wait will make it seem like something is broken.

			// Without this, moderately fast connections transition the placeholder partly in, then transition the real component again.
			// It happens very quickly and it just looks jumpy.
			if (el.getAttribute('data-placeholder')) {
				// Will be removed in @after-enter="clearStyles"
				el.style.display = 'none';
				return;
			}

			if (window.matchMedia && window.matchMedia('(prefers-reduced-motion: reduce)').matches)
				return false;

			// Force the browser to apply CSS changes
			requestAnimationFrame(() => {
				// Force the element to appear invisibly so the size can be measured
				// You would think you'd need position absolute here, but you don't and that messes with dimensions.
				el.style.removeProperty('display');
				el.style.visibility = 'hidden';

				// Trigger resize on conditionally hidden components that responsively render children
				if (this.$children[0] && this.$children[0].handleResize instanceof Function)
					this.$children[0].handleResize();

				// Fix the width of child elements to prevent them from reflowing during the transition
				for (let c = 0; c < el.children.length; c++) {
					const child = el.children[c];
					child.style.width = child.clientWidth + 'px';
				}

				// Measure and store the width and height
				const width = el.clientWidth;
				const height = el.clientHeight;

				// A short lag before the scroll starts makes this look a bit better.
				if (this.scroll) {
					setTimeout(()=> { scrollIntoView(el, { time: this.duration }); }, 200);
				}

				// Display block and hide overflow during transitions
				// (display: table does not support overflow: hidden)
				el.style.display = 'block';
				el.style.overflow = 'hidden';

				// Set transition start values to hide the element
				el.classList.add('cog-slide-transition');

				// Force the browser to apply CSS changes
				requestAnimationFrame(() => {
					// Restore visibility
					el.style.removeProperty('visibility');
					// Set the CSS transition duration
					el.style.transition = `${this.duration}ms ease`;

					// Force the browser to apply CSS changes
					requestAnimationFrame(() => {
						// Set the transition end values to show the element
						el.style.maxWidth = width + 'px';
						el.style.maxHeight = height + 'px';
						el.classList.remove('cog-slide-transition');
					});
				});
			});
		},

		// Initiates the hide transition for the specified element
		beforeLeave: function (el) {
			if (this.form && this.form.disableTransitions) {
				el.style.display = 'none';
				return;
			}

			this.$emit('before-leave');
			if (window.matchMedia && window.matchMedia('(prefers-reduced-motion: reduce)').matches)
				return false;
			// Set transition start values before hiding the element
			el.style.maxWidth = el.clientWidth + 'px';
			el.style.maxHeight = el.clientHeight + 'px';

			// Fix the width of child elements to prevent them from reflowing during the transition
			for (let c = 0; c < el.children.length; c++) {
				const child = el.children[c];
				child.style.width = child.clientWidth + 'px';
			}

			// Force the browser to apply CSS changes
			requestAnimationFrame(() => {
				// Set the CSS transition duration
				el.style.transition = `${this.duration}ms ease`;

				// Force the browser to apply CSS changes
				requestAnimationFrame(() => {
					// Set the transition end values to hide the element
					el.classList.add('cog-slide-transition');
				});
			});
		},

		// Removes all inline styles applied by the transition
		clearStyles: function (el) {
			if (this.form && this.form.disableTransitions)
				return;
			// Remove inline styles on the target element
			el.style.removeProperty('display');
			el.style.removeProperty('overflow');
			el.style.removeProperty('max-width');
			el.style.removeProperty('max-height');
			el.style.removeProperty('transition');

			// Remove inline widths applied to fix the width of child elements
			for (let c = 0; c < el.children.length; c++) {
				const child = el.children[c];
				child.style.removeProperty('width');
			}
		},
		afterEnter(el) {
			if (this.form && this.form.disableTransitions)
				return;
			this.$emit('after-enter');
			this.clearStyles(el);
		},
		// This is on for v-show transitions
		afterLeave(el) {
			if (this.form && this.form.disableTransitions)
				return;
			this.$emit('after-leave');
			this.clearStyles(el);
			el.classList.remove('cog-slide-transition');
			el.style.display = 'none';
		}
	}
};
