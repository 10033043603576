import type Vue from 'vue';

export type EventConstructor = {
	new(type: string, eventInitDict?: EventInit): Event;
};

export function findContainer(component: Vue, match: (parent: Vue) => boolean): Vue {
	if (component.$parent) {
		const parent = component.$parent;

		if (match(parent))
			return parent;

		return findContainer(parent, match);
	}
	else
		return component;
}

export function isComponent(ref: Vue | Element | Vue[] | Element[]): ref is Vue {
	// TODO: What's the best way to detect that something is a component at runtime?
	return (ref as any).$on && typeof (ref as any).$on === 'function';
}
