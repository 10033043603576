import { Deferred } from './deferred';

export interface Reference<T> {
	value: T;
	valuePromise: Promise<T>;
};

export class WriteableReference<T> implements Reference<T> {
	private _value: T;

	private _setValueTask = new Deferred();

	get value(): T {
		return this._value;
	}

	set value(value: T) {
		this._value = value;
		this._setValueTask.resolve();
	}

	get valuePromise(): Promise<T> {
		return this._setValueTask.promise.then(() => this.value);
	}
}
