import type { VueConstructor } from 'vue';
import Vue from 'vue';
import FormBase from 'src/components/FormBase';
import type { FormsModelOptions, PublicFormsModelOptions } from './forms-model';
import { importOptionalModule, FormsModel, FORM_ENTRY_TYPE_NAME } from './forms-model';
import * as core from 'src/framework/model/core';
import FormTheme from './form-theme';

export type FormDefinitionTheme = {
	isChameleon: boolean;
	css: string;
	links: string[];
};

export type FormsModelOptionsGeneratorFunction =
	(core: unknown, getModule: ((name: string) => Promise<any>)) => FormsModelOptions;

export async function prepareModelOptions(modelOptionsFn: FormsModelOptionsGeneratorFunction): Promise<FormsModelOptions> {
	const pendingModules: Promise<unknown>[] = [];
	const modelOptions = modelOptionsFn(core, m => importOptionalModule(m, pendingModules));
	return Promise.all(pendingModules).then(() => modelOptions);
}

export class FormDefinitionBuilder {
	readonly formId: string;
	readonly template: string;
	readonly modelOptions: FormsModelOptionsGeneratorFunction;
	readonly formInternalName: string;
	private _component: VueConstructor<FormBase>;
	private _modelPromise: Promise<FormsModel>;
	private _theme: FormTheme;
	private utcOffset: number;

	constructor(formId: string, formInternalName: string, template: string, modelOptions: FormsModelOptionsGeneratorFunction, utcOffset: number = 0) {
		this.formId = formId;
		this.formInternalName = formInternalName;
		this.template = template;
		this.modelOptions = modelOptions;
		this.utcOffset = utcOffset;
	}

	compileComponent(): VueConstructor<FormBase> {
		if (!this._component) {
			this._component = Vue.extend(FormBase).extend({
				template: this.template
			});
		}
		return this._component;
	}

	createModel(force = false, optionOverrides: PublicFormsModelOptions = {}): Promise<FormsModel> {
		let modelPromise = this._modelPromise;
		if (force || !modelPromise) {
			modelPromise = new Promise<FormsModel>(async resolve => {
				const options = await prepareModelOptions(this.modelOptions);
				options.$utcOffset = this.utcOffset;
				Object.assign(options, optionOverrides);
				// Find the type definition for the form by it's "$id" key
				let entryTypeName = this.formInternalName && options[FORM_ENTRY_TYPE_NAME + '.' + this.formInternalName]
					? FORM_ENTRY_TYPE_NAME + '.' + this.formInternalName
					: Object.keys(options).find(key => !key.startsWith('$') && options[key]['$id'] === this.formId);

				if (entryTypeName)
					delete options[entryTypeName]['$id'];
				else {
					// Fall back to finding a type in the options to extend FormEntry base type
					const formTypeNames = Object.keys(options).filter(key => !key.startsWith('$') && options[key]['$extends'] === FORM_ENTRY_TYPE_NAME);
					if (formTypeNames.length === 1)
						entryTypeName = formTypeNames[0];
					else
						entryTypeName = formTypeNames.find(name => !!this.formInternalName && name.endsWith(`.${this.formInternalName}`));
				}

				if (entryTypeName)
					resolve(FormsModel.createModel(entryTypeName, options));
				else if (process.env.NODE_ENV === 'development')
					console.warn('Could not find type definition for form \'' + this.formId + '\'.');
			});

			if (!force)
				this._modelPromise = modelPromise;
		}

		return modelPromise;
	}

	createTheme(theme: FormDefinitionTheme): FormTheme {
		if (!this._theme) {
			this._theme = FormTheme.create(this.formId, theme);
		}

		return this._theme;
	}
}

export default class FormDefinition {
	readonly formId: string;
	readonly script: HTMLScriptElement;
	readonly component: VueConstructor<FormBase>;
	readonly theme: FormTheme;

	private static defaultModelKey = 'default';
	private models: Map<string, FormsModel> = new Map();
	private builder: FormDefinitionBuilder;

	constructor(formId: string, script: HTMLScriptElement, component: VueConstructor<FormBase>, model: FormsModel, theme: FormTheme) {
		this.formId = formId;
		this.script = script;
		this.component = component;
		this.models.set(FormDefinition.defaultModelKey, model);
		this.theme = theme;
	}

	static async create(formId: string, formInternalName: string, script: HTMLScriptElement, template: string, modelOptionsFn: FormsModelOptionsGeneratorFunction, theme: FormDefinitionTheme, utcOffset: number): Promise<FormDefinition>;
	static async create(formId: string, formInternalName: string, script: HTMLScriptElement, template: string, modelOptions: FormsModelOptions, theme: FormDefinitionTheme, utcOffset: number): Promise<FormDefinition>;
	static async create(formId: string, formInternalName: string, script: HTMLScriptElement, template: string, modelOptions: FormsModelOptionsGeneratorFunction | FormsModelOptions, themeDef: FormDefinitionTheme, utcOffset: number): Promise<FormDefinition> {
		// wrap options in function so builder can assume options is provided via function
		if (typeof modelOptions !== 'function') {
			const optionsObj = modelOptions;
			modelOptions = (() => optionsObj) as FormsModelOptionsGeneratorFunction;
		}

		const builder = new FormDefinitionBuilder(formId, formInternalName, template, modelOptions, utcOffset);
		const model = await builder.createModel();
		const component = builder.compileComponent();
		const theme = builder.createTheme(themeDef);

		const def = new FormDefinition(formId, script, component, model, theme);
		def.builder = builder;
		return def;
	}

	get model(): FormsModel {
		return this.models.get(FormDefinition.defaultModelKey);
	}

	/**
	 * Returns a `FormsModel` that may be isolated from other models created for this `FormDefinition` on the same page.
	 * @param key used to facilitate caching to avoid reconstructing the model for operations occurring in the same logical context
	 */
	async getIsolatedModel(key: string, optionOverrides: PublicFormsModelOptions = {}): Promise<FormsModel> {
		if (this.models.has(key))
			return this.models.get(key);
		else {
			const model = await this.builder.createModel(true, optionOverrides);
			this.models.set(key, model);
			return model;
		}
	}

	destroy() {
		// Remove the script tag from the page so that it can't be used to recreate the form definition
		if (this.script)
			this.script.remove();

		// Clean up any theme styles
		this.theme.destroy();
	}
}
