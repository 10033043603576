var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "cog-choice" },
    [
      _vm._t("default", null, { options: _vm.options }),
      _vm._v(" "),
      !_vm.$parent.isTable
        ? _c(
            "div",
            {
              staticClass: "cog-choice__placeholder",
              class: [
                { "cog-input": !_vm.checkable },
                _vm.checkable && _vm.columns
                  ? `cog-inner-col cog-inner-col--css-columns cog-inner-col--${_vm.columns}`
                  : null
              ]
            },
            [
              _vm.checkable
                ? _c(
                    "div",
                    [
                      _vm._l(_vm.choices, function(choice, i) {
                        return _c(
                          "div",
                          {
                            key: i,
                            staticClass: "cog-choice__checkable-placeholder",
                            class: _vm.columns ? "cog-inner-col__item" : null
                          },
                          [_c("span", [_vm._v(_vm._s(choice))])]
                        )
                      }),
                      _vm._v(" "),
                      _vm.allowOther
                        ? _c(
                            "div",
                            {
                              staticClass: "cog-choice__allow-other-placeholder"
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "cog-field__placeholder-inner" },
                                [_vm._v(" ")]
                              )
                            ]
                          )
                        : _vm._e()
                    ],
                    2
                  )
                : _c(
                    "div",
                    {
                      staticClass: "cog-field__placeholder-inner",
                      class: _vm.columns ? "cog-inner-col__item" : null
                    },
                    [_vm._v("\n\t\t\t \n\t\t")]
                  )
            ]
          )
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }