/**
 * Gets a friendly name for the device type for the given user-agent string
 * @param useragent The user agent string
 * Examples
 * Mozilla/5.0 (iPhone; CPU iPhone OS 15_4_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.4 Mobile/15E148 Safari/604.1
 * Mozilla/5.0 (iPhone; CPU iPhone OS 10_3_1 like Mac OS X) AppleWebKit/603.1.30 (KHTML, like Gecko) Version/10.0 Mobile/14E304 Safari/602.1
 * Mozilla/5.0 (iPad; CPU OS 12_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1 Mobile/15E148 Safari/604.1
 * Mozilla/5.0 (Linux; Android 7.0; SM-G955F) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/100.0.4896.127 Mobile Safari/537.36
 * Mozilla/5.0 (Linux; Android 11; Pixel 5) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/96.0.4664.104 Mobile Safari/537.36
 * Mozilla/5.0 (Windows NT 10.0; Win64; x64; rv:101.0) Gecko/20100101 Firefox/101.0
 */
export function getDeviceType(useragent: string): DeviceType {
	// Modified version of:
	// http://www.competa.com/blog/how-to-detect-mobile-devices-with-vanilla-javascript/
	// Also found in Cognito-core.js
	if (useragent.match(/Windows NT/i))
		return 'windows-desktop';
	else if (useragent.match(/Android/i))
		return 'android';
	else if (useragent.match(/webOS/i))
		return 'webos';
	else if (useragent.match(/iPhone/i))
		return 'iphone';
	else if (useragent.match(/iPod/i))
		return 'ipod';
	else if (useragent.match(/iPad/i))
		return 'ipad';
	else if (useragent.match(/Windows Phone/i))
		return 'windows-phone';
	else if (useragent.match(/SymbianOS/i))
		return 'symbian';
	else if (useragent.match(/RIM/i) || useragent.match(/BB/i))
		return 'blackberry';
	else if (useragent.match(/OS X/i))
		return 'mac-desktop';
	return 'unknown';
}

/**
 * The friendly names for various device types
 */
export type DeviceType = 'windows-desktop' | 'android' | 'webos' | 'iphone' | 'ipod' | 'ipad' | 'windows-phone' | 'symbian' | 'blackberry' | 'mac-desktop' | 'unknown';

/**
 * Determines whether the navigator's user agent matches the given device type query
 * @param navigator The navigator object (i.e. window.navigator)
 * @param query The device type query to check, ex: "iphone|ipod|ipad"
 */
export function isDeviceType(navigator: { userAgent: string }, query: string): boolean {
	const deviceType = getDeviceType(navigator.userAgent);
	const deviceTypesToCheck = query.split('|');
	return deviceTypesToCheck.some(d => d.toLowerCase() === deviceType);
}

export function isLegacyEdge(navigator: { userAgent: string }) {
	return /Edge\//.test(navigator.userAgent);
}

export function isSafari(navigator: { userAgent: string }) {
	const deviceType = getDeviceType(navigator.userAgent);
	return deviceType === 'mac-desktop' || deviceType === 'ipad' || deviceType === 'iphone' || deviceType === 'ipod';
}

export function safariVersion(navigator: { userAgent: string }) {
	const regEx = /Version\/\d*.\d*/;
	if (!isSafari(window.navigator) || !window.navigator.userAgent.match(regEx))
		return false;
	else {
		const versionNumber = parseFloat(window.navigator.userAgent.match(regEx)[0].replace('Version/', ''));
		return isNaN(versionNumber) ? false : versionNumber;
	}
}