<template>
	<transition
		name="visible"
		:duration="duration"
		@before-enter="beforeEnter"
		@before-leave="beforeLeave"
		@after-enter="afterEnter"
		@after-leave="afterLeave"
	>
		<slot></slot>
	</transition>
</template>

<script>
	import '../style/_slide-transition.scss';

	import SlideTransition from '../mixins/slide-transition';

	export default {
		name: 'VisibleTransition',
		mixins: [SlideTransition]
	};
</script>