export type LazyImport<T> = () => Promise<T>;

export async function importWithRetry<T>(getImport: LazyImport<T>): Promise<T> {
	if (window.navigator.onLine) {
		try {
			return await getImport();
		}
		catch {
			return importWithRetry(getImport);
		}
	}
	else {
		return new Promise(resolve => {
			window.addEventListener('online', () => resolve(importWithRetry(getImport)), { once: true });
		});
	}
}
