/* Extracts an error message string from an Error object, or,
*  if passed a string, returns the string. Otherwise return undefined.
*/

export function getErrorMessage(err: any): string {
	if (err instanceof Error)
		return err.message;
	else if (typeof err === 'string')
		return err;
	return undefined;
}