import { ServiceWithSession, isAxiosError } from './base-service';
import type { FileDataRef } from '@cognitoforms/types/server-types/model/file-data-ref';
import type { FormsModel } from 'src/framework/forms-model';
import type { FormSession } from './form-session';
import axios from 'axios';
import { hasValue } from 'src/util/helpers';
import type { Signature } from '@cognitoforms/types/server-types/model/signature';
import { blobToDataUrl } from 'src/util/blob';
import type Log from 'src/framework/logging/log';
import { CError } from 'src/framework/logging/error';
import type { EntityArgsOfType, EntityOfType } from '@cognitoforms/model.js';

interface ProgressEvent {
	loaded: number;
	total: number;
	percent: number;
}

declare type ProgressHandler = (event: ProgressEvent) => void;

interface UploadOptions {
	onProgress?: ProgressHandler;
	encrypt?: boolean;
	cancelPromise?: Promise<any>;
}

export class SignatureImageError extends CError {
	get storageLimitExceeded() {
		return this.additionalProperties.statusCode === 410;
	}

	constructor(file: FileDataRef, statusCode: number) {
		super('Offloaded signature image failed to load: ' + file.Id);
		this.name = 'SignatureImageError';
		this.additionalProperties = {
			fileId: file.Id,
			fileType: file.ContentType,
			fileSize: file.Size,
			statusCode
		};
	}
}

export class SignatureCaptureError extends CError {
	constructor(userAgent: string, exception: string = null) {
		super('An error occurred in front-end signature component.');
		this.name = 'SignatureCaptureError';
		this.additionalProperties = {
			userAgent,
			exception
		};
	}
}

export default class FileService extends ServiceWithSession {
	private inProgress = 0;
	private deserializeFileDataRef: (state: EntityArgsOfType<FileDataRef>) => EntityOfType<FileDataRef>;
	private log: Log;

	constructor(model: FormsModel, session: FormSession, log: Log) {
		super(session);
		this.deserializeFileDataRef = state => model.construct<FileDataRef>('FileDataRef', state);
		this.log = log;
	}

	/**
	 * @returns true if there is at least one upload request in progress.
	 */
	get busy() {
		return this.inProgress > 0;
	}

	async uploadSignature(png: string, svg: string): Promise<{ png: EntityOfType<FileDataRef>; svg: EntityOfType<FileDataRef>; }> {
		if (!hasValue(png) || !hasValue(svg))
			return { png: null, svg: null };

		const params = {};

		this.inProgress++;
		const res = await this.serviceRequest({
			method: 'post',
			endpoint: 'svc/signature/upload',
			params,
			data: { formId: this.session.formId, png, svg }
		});
		this.inProgress--;

		if (res.error)
			throw res.error;

		return {
			png: this.deserializeFileDataRef(res.response.data.png),
			svg: this.deserializeFileDataRef(res.response.data.svg)
		};
	}

	async upload(file: File, { onProgress, encrypt, cancelPromise }: UploadOptions) {
		const params = {};
		if (encrypt)
			params['encrypt'] = '';

		const cancel = axios.CancelToken.source();
		cancelPromise.then(cancel.cancel);

		const data = new FormData();
		data.append('file', file);

		this.inProgress++;
		const res = await this.serviceRequest({
			method: 'post',
			endpoint: 'forms/public/file',
			params,
			data,
			cancelToken: cancel.token,
			onUploadProgress: e => {
				if (e.total > 0)
					e.percent = e.loaded / e.total * 100;
				onProgress(e);
			}
		});
		this.inProgress--;

		if (res.error)
			throw res.error;

		return this.deserializeFileDataRef(res.response.data);
	}

	async getSignatureImageData(signature: Signature, type: 'png' | 'svg'): Promise<string> {
		if (type === 'png' && !signature.PngFile)
			return signature.Png;
		else if (type === 'svg' && !signature.SvgFile)
			return signature.Svg;

		const file = type === 'png' ? signature.PngFile : signature.SvgFile;
		if (file.Size === 0)
			return null;

		const res = await this.serviceRequest({
			method: 'get',
			endpoint: 'forms/public/file',
			responseType: 'blob',
			params: {
				id: file.Id,
				ct: file.ContentType
			}
		});

		if (res.response) {
			const blob = res.response.data as Blob;
			return blobToDataUrl(blob);
		}
		else {
			let err = res.error;
			if (isAxiosError(err)) {
				if (err.response) {
					err = new SignatureImageError(file, err.response.status);
					this.log.error(err);
				}
				else if (err.message === 'Network Error' || err.code === 'ERR_NETWORK') {
					// todo: retry?
					err = new SignatureImageError(file, 0);
					this.log.error(err);
				}
			}

			throw err;
		}
	}

	async downloadFile(file: FileDataRef) {
		const downloadUrl = await this.getDownloadUrl(file);

		const link = document.createElement('a');
		link.style.display = 'none';
		link.href = downloadUrl;
		link.download = file.Name;

		document.body.appendChild(link);
		link.click();

		// setTimeout required for Firefox
		setTimeout(() => {
			link.parentNode.removeChild(link);
		}, 0);
	}

	getDownloadUrl(file: FileDataRef) {
		return this.getUri({
			url: 'forms/public/file',
			params: {
				id: file.Id,
				sessionToken: this.sessionToken,
				ct: file.ContentType
			}
		});
	}

	async getAnonymousDownloadUrl(file: FileDataRef): Promise<string> {
		const result = await this.serviceRequest({
			method: 'get',
			endpoint: 'svc/file-attachment/download-link',
			params: {
				fileId: file.Id
			}
		});

		let token = '';
		if (result.response) {
			token = result.response.data;
		}

		return token;
	}

	async getPreviewUrl(file: FileDataRef): Promise<string> {
		let downloadUrl: string;
		if (this.sessionToken) {
			downloadUrl = this.getDownloadUrl(file);
		}
		else {
			downloadUrl = await this.getAnonymousDownloadUrl(file);
		}

		return `http://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(downloadUrl)}`;
	}

	getThumbnailUrl(file: FileDataRef) {
		if (!file.Id)
			return null;

		return this.getUri({
			url: 'forms/public/thumbnail',
			params: {
				id: file.Id,
				sessionToken: this.sessionToken
			}
		});
	}

	reportSignatureCaptureError(exception?: string) {
		this.log.error(new SignatureCaptureError(window.navigator.userAgent, exception));
	}
}