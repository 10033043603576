// NOTE: Generated by `generate-localizations.ps1`

// SEE: https://element.eleme.io/?ref=madewithvuejs.com#/en-US/component/i18n

const elementSupportedLanguages: { [code: string]: string } = {
	'af-ZA': 'Afrikaans (South Africa)',
	'ar': 'Arabic',
	'bg': 'Bulgarian',
	'ca': 'Catalunya',
	'cs-CZ': 'Czech (Czech Republic)',
	'da': 'Danish',
	'de': 'German',
	'ee': 'Estonian',
	'el': 'Greek',
	'en': 'English',
	'eo': 'Esperanto',
	'es': 'Spanish',
	'eu': 'Basque',
	'fa': 'Farsi',
	'fi': 'Finnish',
	'fr': 'French',
	'he': 'Hebrew',
	'hr': 'Croatian',
	'hu': 'Hungarian',
	'hy-AM': 'Armenian (Armenia)',
	'id': 'Indonesian',
	'it': 'Italian',
	'ja': 'Japanese',
	'kg': 'Kyrgyz',
	'km': 'Khmer',
	'ko': 'Korean',
	'ku': 'Central Kurdish',
	'kz': 'Kazakh',
	'lt': 'Lithuanian',
	'lv': 'Latvian',
	'mn': 'Mongolian',
	'nb-NO': 'Norwegian, Bokmål (Norway)',
	'nl': 'Dutch',
	'pl': 'Polish',
	'pt-br': 'Portuguese (Brazil)',
	'pt': 'Portuguese',
	'ro': 'Romanian',
	'ru-RU': 'Russian (Russia)',
	'sk': 'Slovak',
	'sl': 'Slovenian',
	'sr': 'Serbian',
	'sv-SE': 'Swedish (Sweden)',
	'ta': 'Tamil',
	'th': 'Thai',
	'tk': 'Turkmen',
	'tr-TR': 'Turkish (Turkey)',
	'ua': 'Ukrainian',
	'ug-CN': 'Uighur',
	'uz-UZ': 'Uzbek',
	'vi': 'Vietnamese',
	'zh-CN': 'Chinese (Simplified, PRC)',
	'zh-TW': 'Chinese (Traditional, Taiwan)'
};

export default elementSupportedLanguages;
