var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "header",
        {
          staticClass: "cog-header",
          class: [
            {
              "cog-wrapper": _vm.layout !== "stretch",
              ["cog-header--" + _vm.layout]: _vm.logo
            },

            "cog-header--" + _vm.alignment
          ]
        },
        [
          _vm.logo
            ? _c("img", { attrs: { src: _vm.logo, alt: _vm.alt || false } })
            : _vm._e(),
          _vm._v(" "),
          _vm.title
            ? _c(
                "div",
                {
                  staticClass: "cog-header__text",
                  class: {
                    "cog-wrapper":
                      _vm.layout === "stretch" || _vm.layout === "logo-only"
                  }
                },
                [
                  _c("h1", { staticClass: "cog-heading" }, [
                    _vm._v(_vm._s(_vm.title))
                  ]),
                  _vm._v(" "),
                  _vm.description && !_vm.showConfirmation
                    ? _c("div", {
                        staticClass: "cog-header__description",
                        domProps: { innerHTML: _vm._s(_vm.description) }
                      })
                    : _vm._e()
                ]
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }