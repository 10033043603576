import { harvestCssVars } from '@cognitoforms/utils/css-helper';

let cssVars = null;
let ponyfill = null;
const callBacks = [];
const promise = (process.env.IS_LEGACY ? import(/* webpackChunkName: "css-vars-polyfill" */'css-vars-ponyfill').then(module => (ponyfill = module.default)) : null);

function setCSSVars() {
	if (!cssVars) {
		// Only grab the first instance of css vars since we do not support using this polyfill on multiple differently-styled forms.
		// Extract everything inside the top level selector block and construct an object map of the declarations.
		cssVars = harvestCssVars(document.querySelector('.cog-css-variables').innerHTML);
	}
}

function undoPonyfill() {
	document.querySelectorAll('[data-cssvars="out"]').forEach(style => style.parentElement.removeChild(style));
	document.querySelectorAll('[data-cssvars]').forEach(style => {
		style.removeAttribute('data-cssvars');
		style.removeAttribute('data-cssvars-job');
		style.removeAttribute('data-cssvars-group');
		style.removeAttribute('disabled');
	});
}

export async function processCssVars({ cleanSlate }: { cleanSlate: boolean }) {
	if (!promise)
		return;

	if (cleanSlate) {
		cssVars = null;
		undoPonyfill();
	}

	// Create JS object from CSS variables
	setCSSVars();

	if (!ponyfill)
		await promise;

	ponyfill({
		include: '.cog-style',
		variables: cssVars,
		onComplete: (_cssText, _styleNodes, _cssVariables) => {
			// in legacy browsers, we need to trigger resize after css vars are polyfilled since that affects size/layout
			const evt = document.createEvent('UIEvents');
			evt.initUIEvent('resize', true, false, window, 0);
			window.dispatchEvent(evt);
			// disable processed styles because of strange behavior in IE. Cf. 19849
			const processedStyles = document.querySelectorAll('.cog-style[data-cssvars="src"]:not([disabled])') as NodeListOf<SVGStyleElement>;
			for (let i = 0; processedStyles.length > i; i++)
				processedStyles[i].disabled = true;

			callBacks.forEach((cb) => cb());
		}
	});
}

export default {
	methods: {
		setPolyFillCallBack(fn: () => void) {
			callBacks.push(fn);
		}
	},
	async mounted() {
		if (promise && this.$el.classList && document.querySelector('.cog-style:not([data-cssvars])'))
			processCssVars({ cleanSlate: false });
	}
};