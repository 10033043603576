import Vue from 'vue';
import type { CultureInfo, Entity, EntityOfType } from '@cognitoforms/model.js';
import VueModel from '@cognitoforms/vuemodel';
import Header from './Header.vue';
import Row from './Row.vue';
import Col from './Col.vue';
import Page from './Page.vue';
import Field from './Field.vue';
import Void from './Void';
import Choice from './Choice.vue';
import SectionPlaceholder from './SectionPlaceholder.vue';
import scrollIntoView from 'src/util/scroll-into-view';
import cssVars from '../mixins/css-vars';
import VisibleTransition from './VisibleTransition.vue';
import Component, { mixins } from 'vue-class-component';
import { Prop, Provide, Watch } from 'vue-property-decorator';
import type { BeforeNavigateEventData, AfterNavigateEventData, ReadyEventData, BeforeSubmitEventData, AfterSubmitEventData, UploadFileEventData, AllowedActionsChangedEventData, OrderUpdatedEventData, ActionChangedEventData } from '../framework/public/events';
import { FormEvents, NavigationDirection } from '../framework/public/events';
import type FileService from 'src/web-api/file-service';
import type { ObjectLookup } from 'src/util';
import { parseUrlHash } from 'src/util';
import { FormEventScope } from 'src/framework/eventing/form-event-scope';
import { FormEvent } from 'src/framework/eventing/form-event';
import type { EntryService, EntrySubmissionResult } from 'src/web-api/entry-service';
import { SubmissionResultStatus, validateByPage } from 'src/web-api/entry-service';
import type { FileDataRef } from '@cognitoforms/types/server-types/model/file-data-ref';
import { FormStatus } from 'src/mixins/form-status';
import type { FormsModel } from 'src/framework/forms-model';
// Just importing the type
import type EntryViewService from 'src/web-api/entry-views/entry-view-service';
import ToastMessage, { closeAllToastMessages, onToastMessageRequested } from './ToastMessage';
import type { FormSession } from 'src/web-api/form-session';
import type QuantityService from 'src/web-api/quantity-service';
import { binaryInsert } from 'src/util/array';
import type { ElMessageOptions } from '@cognitoforms/element-ui/types/message';
import type { Order } from '@cognitoforms/types/server-types/payment/model/order';
import type { FormEntryExtensions, FormEntryWithOptionalOrder, FormEntryWithOrder, FormEntryWorkflowExtensions } from 'src/framework/model/extensions/form-entry-extensions';
import type { PaymentToken } from '@cognitoforms/types/server-types/payment/model/payment-token';
import type { WorkflowActionExtensions } from 'src/framework/model/extensions/workflow-action-extensions';
import type { OrderExtensions } from 'src/framework/model/extensions/payment-extensions';
import { enableResizeDetection } from './form-resizing';
import { isDeviceType, isSafari, safariVersion } from 'src/util/user-agent';
import type Log from '../framework/logging/log';
import { CaptchaError, InvalidSharedLinkError, PublicLinksDisabledError } from 'src/web-api/base-service';
import type { AuthenticationCompleteEvent, PaymentProcessorComponent } from 'src/framework/payment/payment-types';
import type { WorkflowAction } from '@cognitoforms/types/server-types/forms/model/workflow-action';
import { ConcurrentEntryConflict } from 'src/web-api/concurrent-entry-service';
import OrderBuilder, { rebuildOrder } from 'src/framework/payment/order-builder';
import type { LazyImport } from 'src/util/retryable-import';
import { importWithRetry } from 'src/util/retryable-import';
import ResizeObserver from 'resize-observer-polyfill';
import type AuditService from 'src/web-api/audit-service';
import type PaymentService from 'src/web-api/payment-service';
import { visitEntity } from 'src/util/model';
import { PaymentTokenStatus } from '@cognitoforms/types/server-types/payment/model/payment-token-status';
import { isSignature } from 'src/framework/model/core';
import { Deferred } from 'src/util/deferred';
import type { GoogleMapsLoader } from './Address/google-maps-loader';
import { EntryStatus } from './EntryStatus';
import type { ThemeData } from './ThemeData';

if (process.env.IS_LEGACY)
	Vue.mixin(cssVars);

// Used in all forms, safe to always include
Vue.component('CHeader', Header);
Vue.component('CRow', Row);
Vue.component('CCol', Col);
Vue.component('CPage', Page);
Vue.component('CField', Field);
Vue.component('CVoid', Void);
Vue.component('VisibleTransition', VisibleTransition);

// Not in every form but small and including here
// helps in determining loading placeholder height.
Vue.component('CChoice', Choice);

const _asyncComponentImports = new Set<Promise<unknown>>();
function registerAsyncComponent<T>(getImport: LazyImport<T>): Promise<T> {
	const importPromise = importWithRetry(getImport);
	_asyncComponentImports.add(importPromise);
	return importPromise;
}

@Component({
	name: 'CForm',
	components: {
		/**
		 * The components below represent what can/should appear in a form template generated by the server.
		 **/
		CGoogleAnalytics: () => registerAsyncComponent(() => import(/* webpackChunkName: "GoogleAnalytics" */ './analytics/GoogleAnalytics')),
		CTrackingPixel: () => registerAsyncComponent(() => import(/* webpackChunkName: "TrackingPixel" */ './analytics/TrackingPixel')),
		CPageProgress: () => registerAsyncComponent(() => import(/* webpackChunkName: "PageProgress" */ './PageProgress.vue')),
		CRepeatingSection: () => ({
			component: registerAsyncComponent(() => import(/* webpackChunkName: "RepeatingSection" */ './RepeatingSection.vue')),
			loading: SectionPlaceholder,
			delay: 0
		}),
		CTable: () => ({
			component: registerAsyncComponent(() => import(/* webpackChunkName: "Table" */ './Table.vue')),
			loading: SectionPlaceholder,
			delay: 0
		}),
		CSection: () => ({
			component: registerAsyncComponent(() => import(/* webpackChunkName: "Section" */ './Section.vue')),
			loading: SectionPlaceholder,
			delay: 0
		}),
		CRatingScale: () => ({
			component: registerAsyncComponent(() => import(/* webpackChunkName: "RatingScale" */ './RatingScale.vue')),
			loading: SectionPlaceholder,
			delay: 0
		}),
		CContent: () => registerAsyncComponent(() => import(/* webpackChunkName: "Content" */ './Content.vue')),
		CInput: () => registerAsyncComponent(() => import(/* webpackChunkName: "Input" */ './Input.vue')),
		CInvalidForm: () => registerAsyncComponent(() => import(/* webpackChunkName: "InvalidForm" */ './InvalidForm.vue')),
		CNumberDropdown: () => registerAsyncComponent(() => import(/* webpackChunkName: "NumberDropdown" */ './NumberDropdown.vue')),
		CName: () => registerAsyncComponent(() => import(/* webpackChunkName: "Name" */ './Name.vue')),
		CAddress: () => registerAsyncComponent(() => import(/* webpackChunkName: "Address" */ './Address/Address.vue')),
		CSpinner: () => registerAsyncComponent(() => import(/* webpackChunkName: "Spinner" */ './Spinner.vue')),
		CSelect: () => registerAsyncComponent(() => import(/* webpackChunkName: "Select" */ './Select.vue')),
		CDate: () => registerAsyncComponent(() => import(/* webpackChunkName: "Date" */ './Date.vue')),
		CTime: () => registerAsyncComponent(() => import(/* webpackChunkName: "Time" */ './Time.vue')),
		CCheckboxGroup: () => registerAsyncComponent(() => import(/* webpackChunkName: "CheckboxGroup" */ './CheckboxGroup.vue')),
		CCheckbox: () => registerAsyncComponent(() => import(/* webpackChunkName: "Checkbox" */ './Checkbox.vue')),
		CRadioGroup: () => registerAsyncComponent(() => import(/* webpackChunkName: "RadioGroup" */ './RadioGroup.vue')),
		COrder: () => registerAsyncComponent(() => import(/* webpackChunkName: "Order" */ './Order/Order.vue')),
		COrderDetails: () => registerAsyncComponent(() => import(/* webpackChunkName: "OrderDetails" */ './Order/OrderDetails.vue')),
		CPayment: () => registerAsyncComponent(() => import(/* webpackChunkName: "Payment" */ './Payment/Payment.vue')),
		CStripePayment: () => registerAsyncComponent(() => import(/* webpackChunkName: "StripePayment" */ './Payment/StripePayment.vue')),
		CSquarePayment: () => registerAsyncComponent(() => import(/* webpackChunkName: "SquarePayment" */ './Payment/SquarePayment.vue')),
		CPayPalPayment: () => registerAsyncComponent(() => import(/* webpackChunkName: "PayPalPayment" */ './Payment/PayPalPayment.vue')),
		CBillingInfo: () => registerAsyncComponent(() => import(/* webpackChunkName: "BillingInfo" */ './Order/BillingInfo.vue')),
		CTransactionDetails: () => registerAsyncComponent(() => import(/* webpackChunkName: "TransactionDetails" */ './Order/TransactionDetails.vue')),
		CSwitch: () => registerAsyncComponent(() => import(/* webpackChunkName: "Switch" */ './Switch.vue')),
		CSignature: () => registerAsyncComponent(() => import(/* webpackChunkName: "Signature" */ './Signature.vue')),
		CUpload: () => registerAsyncComponent(() => import(/* webpackChunkName: "Upload" */ './Upload.vue')),
		CSaveResumeDialog: () => registerAsyncComponent(() => import(/* webpackChunkName: "SaveResumeDialog" */ './SaveResumeDialog.vue')),
		CBranding: () => registerAsyncComponent(() => import(/* webpackChunkName: "Branding" */ './Branding.vue')),
		CAbuse: () => registerAsyncComponent(() => import(/* webpackChunkName: "Abuse" */ './Abuse.vue')),
		CConfirmation: () => registerAsyncComponent(() => import(/* webpackChunkName: "Confirmation" */ './Confirmation.vue')),
		CLookup: () => registerAsyncComponent(() => import(/* webpackChunkName: "Lookup" */ './Lookup.vue')),
		CCascadeFilter: () => registerAsyncComponent(() => import(/* webpackChunkName: "CascadeFilter" */ './CascadeFilter.vue')),
		CWebsite: () => registerAsyncComponent(() => import(/* webpackChunkName: "Website" */ './Website.vue')),
		Portal: () => registerAsyncComponent(() => import(/* webpackChunkName: "VuePortal" */ 'portal-vue').then(f => f.Portal)),
		PortalTarget: () => registerAsyncComponent(() => import(/* webpackChunkName: "VuePortal" */ 'portal-vue').then(f => f.PortalTarget))
	}
})
export default class FormBase extends mixins(VueModel.mixins.SourceRoot) {
	@Provide()
	get form() {
		return this;
	}

	@Prop()
	entry: EntityOfType<FormEntryWithOptionalOrder & FormEntryExtensions & FormEntryWorkflowExtensions>;

	@Provide()
	get flags() {
		return this.session.flags;
	}

	@Provide('formEvents')
	@Prop({ default: () => new FormEventScope() })
	publicEvents: FormEventScope;

	@Prop()
	fileService: FileService;

	@Prop()
	entryService: EntryService;

	@Prop()
	@Provide()
	log: Log;

	@Prop()
	@Provide()
	formsModel: FormsModel;

	@Prop()
	entryToken: string;

	@Prop()
	chameleon: boolean;

	@Prop()
	@Provide()
	entryViewService: EntryViewService;

	@Prop()
	@Provide()
	quantityService: QuantityService;

	@Prop()
	@Provide()
	auditService: AuditService;

	@Prop()
	@Provide()
	paymentService: PaymentService;

	@Prop()
	@Provide()
	session: FormSession;

	@Prop({ default: 1 })
	startingPage: number;

	@Prop({ default: true })
	available: boolean;

	@Prop()
	notAvailableMessage: string;

	@Prop()
	@Provide()
	$resource: (name: string, params?: ObjectLookup<string>) => string;

	@Prop()
	@Provide()
	$format: (value: number | Date, format: string) => string;

	@Prop()
	@Provide()
	$parse: (type: NumberConstructor | DateConstructor, value: string, format?: string) => number | Date;

	@Prop()
	@Provide()
	$culture: CultureInfo;

	@Prop()
	@Provide()
	$namespace: object;

	@Prop()
	@Provide()
	$expandDateFormat: (format: string) => string;

	@Prop({ default: true })
	@Provide()
	showPageBreaks: boolean;

	@Prop({ default: false })
	@Provide()
	redirectingToEntryView: boolean;

	@Prop({ default: true })
	showSaveAndResumeDialog: boolean;

	@Prop({ default: true })
	useThemeSettings: boolean;

	@Prop({ default: null })
	googleMapsLoader: GoogleMapsLoader;

	internalEntryToken: string;
	isTable = false;
	showConfirmation = false;
	showReceipt = false;
	entryDetailsVisible = false;
	showNavigation = true;
	pages = [];
	fields = [];
	pageNumber = 1;
	entryStatus: EntryStatus = EntryStatus.Default;
	saveStatus: FormStatus = FormStatus.Default;
	submitStatus: FormStatus = FormStatus.Default;
	hostingPageUrl = location.href;
	saveResumeInfo = {
		open: false,
		message: '',
		emailMessage: '',
		link: '',
		emailStatus: FormStatus.Default,
		emailAddress: '',
		enableSend: true
	};

	paymentError = '';
	documents = null;

	focusPlace = null;
	headerRef: Vue;
	bodyRef: HTMLElement;
	_keydownListener = null;
	enforceValidation = true;
	pageTransitioning = false;
	themeSettings: ThemeData = {
		logo: null,
		logoAlt: '',
		headerLayout: '',
		headerAlignment: '',
		isChameleon: null
	};

	disableTransitions = false;
	resizeObserver = new ResizeObserver(() => this.handleResize());

	scrollMarginTop: number;

	withTransitionsDisabled(cb: (form: FormBase) => Promise<any> | void): Promise<void> {
		return new Promise(resolve => {
			this.disableTransitions = true;
			this.$nextTick(async () => {
				await cb(this);
				setTimeout(() => {
					requestAnimationFrame(() => {
						this.disableTransitions = false;
						resolve();
					});
				}, 1);
			});
		});
	}

	get actions(): EntityOfType<WorkflowAction & WorkflowActionExtensions>[] {
		return this.entry.Workflow_Actions || [];
	}

	get allowedActions(): EntityOfType<WorkflowAction & WorkflowActionExtensions>[] {
		return this.entry.Allowed_Actions || [];
	}

	get action(): EntityOfType<WorkflowAction & WorkflowActionExtensions> {
		if (this.entry.Entry.Action) {
			return this.actions.find(a => a.ActionName === this.entry.Entry.Action);
		}
	}

	@Watch('submitStatus')
	submitStatusWatcher() {
		this.entry.Action_IsLocked = this.submitStatus !== FormStatus.Default;
	}

	@Watch('allowedActions', { immediate: true })
	allowedActionWatcher() {
		// When allowed actions change, let the form handle know (for the entries page)
		this.publicEvents.emit(new FormEvent<AllowedActionsChangedEventData>(FormEvents.AllowedActionsChanged, {
			allowedActions: this.allowedActions.map(a => a.serialize() as WorkflowAction)
		}));
	}

	@Watch('action')
	actionWatcher() {
		// When the action changes, let the form handle know (for the entries page)
		this.publicEvents.emit(new FormEvent<ActionChangedEventData>(FormEvents.ActionChanged, {
			action: this.action ? this.action.serialize() as WorkflowAction : null
		}));
	}

	get order() {
		return this.entry.Order as EntityOfType<Order & OrderExtensions>;
	}

	_orderBuilder;
	get orderBuilder() {
		if (!this._orderBuilder)
			this._orderBuilder = new OrderBuilder(this.entry as EntityOfType<FormEntryWithOrder>);
		return this._orderBuilder;
	}

	get orderInfo() {
		return this.orderBuilder.getOrderInfo();
	}

	get entity() {
		return this.entry;
	}

	get showAllPages() {
		return !this.showNavigation || (this.showNavigation && this.showConfirmation && this.entryDetailsVisible);
	}

	get visiblePages() {
		return this.pages.filter(page => page && page.visible);
	}

	get visiblePageNumber() {
		return Math.max(this.visiblePages.indexOf(this.currentPage) + 1, 1);
	}

	get lastVisiblePageNumber() {
		if (this.visiblePages[this.visiblePages.length - 1])
			return this.visiblePages[this.visiblePages.length - 1].number;
		else
			return 0;
	}

	get enablePaging() {
		return this.showPageBreaks && !this.isReadonly;
	}

	get currentPage() {
		return this.pages.length && !this.showConfirmation
			? this.pages[this.pageNumber]
			: {};
	}

	// Pages is 1-based (so the first page is at index 1)
	get isMultiPage() {
		return this.pages.length > 2;
	}

	/**
	 * Will always be the very last page, visible or not.
	 */
	get submissionPage() {
		return this.pages[this.pages.length - 1];
	}

	/**
	 * Are we on the last visible page?
	 */
	get onLastPage() {
		return this.pages.slice(this.pageNumber + 1).every(p => !p.visible) || !this.enablePaging;
	}

	get hasPreviousPage() {
		return this.pages.slice(0, this.pageNumber).some(p => p.visible);
	}

	get nextButton() {
		return this.onLastPage && this.submissionPage ? this.submissionPage.nextButton : this.currentPage.nextButton;
	}

	get isExistingEntry() {
		return !!this.entry.Id;
	}

	get showOrder() {
		// For workflow forms, show the order when it is open or when it has just been paid or when the order has been paid and is readonly
		if (this.flags.workflowEnabled) {
			if (this.showConfirmation)
				return this.showReceipt && !!this.order.LineItems.length;
			else {
				const readonlyAndPaid = !this.order.IsOpen && this.readonly;
				return !this.flags.forceHideOrder && this.order && (this.order.IsOpen || readonlyAndPaid || this.showReceipt);
			}
		}

		// For non-workflow forms, the order should always be shown. Whether it's readonly or not will be determined by this.readonly
		return true;
	}

	/**
	 * Determines whether the order is in readonly mode or not
	 */
	get orderInReadonlyMode() {
		if (this.flags.workflowEnabled)
			// Workflow forms should show the order in readonly mode when the order is closed
			return !this.order.IsOpen;
		else
			// Non-WF forms should show the order in readonly mode when the form is readonly
			return this.readonly;
	}

	get processPayment() {
		const orderExists = this.order && this.order.IsOpen;
		const shouldSaveCard = this.flags.saveCustomerCard && this.entry.Save_Customer_Card && !!this.entry.Billing_Email_Field;
		const shouldCollectPayment = this.entry.Require_Payment && this.order.AmountPaid === 0 && this.order.OrderAmount > 0 && this.isActionPayable(this.action);

		return Boolean(orderExists && (shouldSaveCard || shouldCollectPayment));
	}

	get shouldRebuildOrder() {
		const closedOrderExists = this.order && !this.order.IsOpen;
		const isPaymentForm = this.entry.meta.type.getProperty('Order') != null;

		return Boolean(!closedOrderExists && isPaymentForm);
	}

	/**
	 * An action is not considered payable if the entry is incomplete and the action does not change the status.
	 */
	private isActionPayable(action) {
		return !this.flags.workflowEnabled || (this.flags.workflowEnabled && action && action.NewStatus !== 0 && !(action.NewStatus == null && this.entry.Entry.Status === 'Incomplete'));
	}

	get hasPayableAction() {
		return !this.showConfirmation && (this.flags.workflowEnabled ? this.allowedActions.some(a => this.isActionPayable(a)) : true);
	}

	get isChameleon() {
		if (typeof this.themeSettings.isChameleon === 'boolean')
			return this.themeSettings.isChameleon;
		return this.chameleon;
	}

	@Watch('isReadonly')
	readonlyChangedTemp() {
		if (this.readonly !== this.isReadonly)
			this.readonly = this.isReadonly;
	}

	@Watch('$source')
	sourceRootChanged() {
		this.$source.readonly = this.session.isReadonly || this.readonly || this.showConfirmation || this.entry.Form_ReadOnly;
		this.showReceipt = false;
	}

	@Watch('order.Rebuild_Count')
	orderModified() {
		if (this.order)
			this.publicEvents.emit(new FormEvent<OrderUpdatedEventData>(FormEvents.OrderUpdated, {
				order: this.order.serialize() as Order
			}));
	}

	/**
	 * Returns true if the form is effectively readonly.
	 */
	get isReadonly() {
		return this.session.isReadonly || this.$source.readonly;
	}

	// Make the source readonly when either the readonly or showConfirmation properties are true
	@Watch('readonly')
	readonlyChanged() {
		this.$source.readonly = this.session.isReadonly || this.readonly || this.showConfirmation;

		if (!this.flags.workflowEnabled)
			this.showNavigation = !this.$source.readonly;
	}

	// Make the source readonly when either the readonly or showConfirmation properties are true
	@Watch('showConfirmation')
	showConfirmationChanged() {
		this.$source.readonly = this.session.isReadonly || this.readonly || this.showConfirmation;
		this.readonly = this.session.isReadonly || this.$source.readonly;
	}

	@Watch('pageNumber')
	pageNumberChanged(newNumber, oldNumber) {
		closeAllToastMessages();
		// auto save after page navigation
		this.autoSave();

		this.publicEvents.emit(new FormEvent<AfterNavigateEventData>(FormEvents.AfterNavigate, {
			entry: this.entry.serialize(),
			direction: newNumber > oldNumber ? NavigationDirection.Forward : NavigationDirection.Backward,
			sourcePage: {
				title: this.pages[oldNumber].title,
				number: oldNumber,
				name: 'page' + oldNumber
			},
			destinationPage: {
				title: this.currentPage.title,
				number: newNumber,
				name: 'page' + newNumber
			}
		}));
	}

	@Watch('entry.Form_ReadOnly', { immediate: true })
	checkFormReadOnly() {
		if (this.flags.workflowEnabled && this.entry.Form_ReadOnly !== undefined)
			this.$source.readonly = this.readonly = this.session.isReadonly || this.entry.Form_ReadOnly;
	}

	handleEntryDetailsVisibility(entryDetailsVisible: boolean) {
		this.entryDetailsVisible = entryDetailsVisible;
	}

	handleSubmit(event: Event) {
		const submitButton = (event.target as HTMLElement).closest('[type="submit"]');
		const onLastField = document.activeElement === this.getLastFieldElement();
		const onSubmitButton = submitButton.contains(document.activeElement) || (isSafari(window.navigator) && document.activeElement === this.$el);

		if (onLastField || onSubmitButton) {
			const actionName = this.flags.workflowEnabled ? submitButton.getAttribute('data-action-name') : null;
			this.navigate({ forward: true, validateCaptcha: this.currentPage.getCaptchaValidator(), action: actionName });
		}
	}

	registerPage(page) {
		Vue.set(this.pages, page.number, page);
	}

	unregisterPage(page) {
		Vue.set(this.pages, page.number, null);
	}

	registerField(field) {
		binaryInsert(this.fields, field, f => f.pageNumber);
	}

	unregisterField(field) {
		const index = this.fields.indexOf(field);
		this.fields.splice(index, 1);
	}

	unsubscribeFromToastRequests: () => void;
	created() {
		this.entryStatus = EntryStatus.Ready;
		this.enforceValidation = !this.flags.disableValidation;
		this.disableTransitions = true;
		this.readonlyChanged();
		window.addEventListener('beforeprint', this.handlePrint);
		window.addEventListener('offline', this.handleOffline);

		this.unsubscribeFromToastRequests = onToastMessageRequested((formId, _message) => {
			if (formId === this.session.formId)
				this.publicEvents.emit(new FormEvent(FormEvents.ScrollToTop));
		});
	}

	mounted() {
		this.log && this.log.pageLoad();
		const browserSafariVersion = safariVersion(window.navigator);
		if (browserSafariVersion && browserSafariVersion < 14.5)
			this.$el.setAttribute('data-old-safari', 'true');

		// These refs are resolvable immediately because they are not async components
		this.headerRef = this.$refs.header as Vue;
		this.bodyRef = this.$refs.body as HTMLElement;

		// Get the scroll-margin-top from the form element to prevent form from scrolling behind elements.
		requestAnimationFrame(() => {
			this.scrollMarginTop = parseInt(getComputedStyle(this.form.$el).scrollMarginTop);
		});

		this.resizeObserver.observe(this.$el);

		if (this.flags.embedded) {
			this.hostingPageUrl = window.location.href;
			this.publicEvents.once(FormEvents.CaptureHost, e => (this.hostingPageUrl = e.data.url));
			enableResizeDetection(this);
		}

		// When the form has been completely rendered, emit the ready event
		this.$nextTick(async () => {
			await this.waitForFieldsToUpdate();
			this.disableTransitions = false;
			this.publicEvents.emit(new FormEvent<ReadyEventData>(FormEvents.Ready, { entry: this.entry.serialize() }));
			if (this.startingPage > 1)
				this.navigateTo(this.startingPage);
		});

		// The keydown event must be on the document as opposed to the form because
		// when an element is removed IE/Edge removes focus from the form entirely and the
		// next tab is not on the form but on the body.
		this._keydownListener = (e) => {this.handleTab(e); this.handleLastFieldEnter(e);};
		document.addEventListener('keydown', this._keydownListener);

		if (this.entryToken)
			this.internalEntryToken = decodeURIComponent(this.entryToken.slice(0, -1));

		if (isSafari(window.navigator))
			this.$el.setAttribute('data-browser', 'safari');

		if (this.shouldRebuildOrder)
			rebuildOrder(this.entry as EntityOfType<FormEntryWithOrder>, this.order);
	}

	destroyed() {
		document.removeEventListener('keydown', this._keydownListener);
		window.removeEventListener('beforeprint', this.handlePrint);
		window.removeEventListener('offline', this.handleOffline);
		closeAllToastMessages();
		this.unsubscribeFromToastRequests();
		this.resizeObserver.unobserve(this.$el);
	}

	isDeviceType(query: string): boolean {
		return isDeviceType(window.navigator, query);
	}

	handleLastFieldEnter(e: KeyboardEvent) {
		const isSafariOrMobile = this.flags.mobile || isSafari(window.navigator);
		if (e.key && e.key.toLowerCase() === 'enter' && !this.onLastPage && isSafariOrMobile && this.getLastFieldElement() === e.target)
			this.navigate({ forward: true, validateCaptcha: this.currentPage.getCaptchaValidator() });
	}

	handleTab(e) {
		if (false || e.which !== 9 || !this.focusPlace)
			return false;

		// Browsers that don't handle focus of removed elements will place focus on body, thus if
		// the focus isn't on the body the recorded focus has become obsolete and should be nulled out.
		if (document.activeElement !== document.body) {
			this.focusPlace = null;
			return false;
		}

		// Find the index of the nearest element that still exists. Then focus that element.
		const tabDirection = e.shiftKey ? -1 : 1;
		let indexToTry = this.focusPlace.focusIndex + tabDirection;

		while (!this.$el.contains(this.focusPlace.elements[indexToTry]) && indexToTry >= 0 && indexToTry < this.focusPlace.elements.length) {
			indexToTry = indexToTry + tabDirection;
		}

		if (indexToTry >= 0) {
			e.preventDefault();
			this.focusPlace.elements[indexToTry].focus();
		}

		// Remove record of focus placement
		this.focusPlace = null;
	}

	handlePrint(e) {
		// Mark the sufficiently short rows so we can avoid page breaks inside of them.
		document.querySelectorAll('.cog-row, .cog-payment').forEach(row => {
			row.clientHeight < 500 ? row.classList.add('cog-row--short') : row.classList.remove('cog-row--short');
		});
	}

	recordFocusPlace(focusPlace) {
		this.focusPlace = {
			focusIndex: focusPlace.focusIndex,
			elements: focusPlace.elements
		};
	}

	handleResize() {
		// This is to fix an issue with seamless embedded tables, triggering a window resize because responsive-tables is only
		// listening for window resize events
		window.dispatchEvent(new Event('resize'));

		requestAnimationFrame(()=>{
			const formContainerEl = this.$el.querySelector('.cog-form__container');
			if (!formContainerEl)
				return;
			const width = Math.ceil(this.$el.querySelector('.cog-form__container').clientWidth / 25) * 25;
			const widths = [];
			for (let size = width; size >= 200; size = size - 25) {
				if (size <= 650 || size % 100 === 0) widths.push(size);
			}
			this.$el.setAttribute('data-width', widths.join(' '));
		});
	}

	handleOffline() {
		const offlineMessage = this.createToastMessage({ type: 'error', showClose: false, message: this.$resource('network-unavailable-message') });
		const handleOnline = () => {
			offlineMessage.close();
			window.removeEventListener('online', handleOnline);
		};
		window.addEventListener('online', handleOnline);
	}

	/**
	 *	1. Measure the leaving page's height
	 *	2. Set the leaving page's height to the form body
	 *	3. Set the leaving page to position: absolute (with the class cog-page-transition)
	 *	4. Wait a tick to give CSS a chance to notice the difference (and apply transition)
	 *	5. Set the height of the entering page to the form body
	 */
	pageBeforeEnter() {
		this.pageTransitioning = true;
		this.bodyRef.style.minHeight = this.bodyRef.clientHeight + 'px';
	}

	pageEntering(height) {
		this.publicEvents.emit(new FormEvent(FormEvents.ScrollToTop));
		requestAnimationFrame(()=>{
			const currentHeight = this.bodyRef.clientHeight;
			const pageSpeed = +getComputedStyle(document.querySelector('.cog-page')).transitionDuration.replace('s', '') * 1000;
			this.bodyRef.style.minHeight = currentHeight + 'px';

			this.bodyRef.classList.add('cog-page-transition');
			setTimeout(() => {
				this.bodyRef.style.minHeight = height + 'px';
			});

			// If the top of the page is above the top of the viewport (thus requiring scroll),
			// add a delay to the sideways scroll so the animations (scroll up and page slide in) happen in stages.
			const scrollToEl = this.$root.$el.querySelector('.cog-page-progress') || this.bodyRef;
			if (scrollToEl.getBoundingClientRect().top < 0) {
				const head = document.head;
				const style = document.createElement('style');
				style.setAttribute('id', 'cog-page-conditional-transition-styles');
				style.innerHTML = `.cog-page.cog-page-enter-active,
			.cog-page.cog-page-leave-active {
				transition: min-height linear ${pageSpeed}ms, opacity linear ${pageSpeed * 1.2}ms, transform ease-in-out ${pageSpeed}ms ${pageSpeed * .3}ms;
			}`;
				head.appendChild(style);
			}

			/**
		 * Because the height of the incoming page can change while it is in transition,
		 * adjust the height of the body while pages are in transition.
		 * For example, if a tall field loads during the transition.
		 */
			setTimeout(() => {
				const activePage = document.querySelector('.cog-page-enter-active');
				if (activePage)
					this.bodyRef.style.minHeight = activePage.clientHeight + 'px';
			}, pageSpeed / 4);

			if (!this.getFirstInvalidFieldElement())
			// Grab the scroll-margin-top from the form element and use as our topOffset
				scrollIntoView(this.$refs.formContainer as HTMLElement, { time: pageSpeed, align: { top: 0, topOffset: this.scrollMarginTop } }, null, this.flags.useNativeScrolling);
		});
	}

	pageEntered() {
		requestAnimationFrame(() => {
			this.bodyRef.classList.remove('cog-page-transition');

			// Delay removing min-height to prevent slight jump in some cases. See comment on #16340.
			setTimeout(() => {
				this.bodyRef.style.minHeight = null;
			}, 200);

			const transitionStyles = document.getElementById('cog-page-conditional-transition-styles');
			if (transitionStyles)
				transitionStyles.parentNode.removeChild(transitionStyles);

			// Do not reset scroll if an error is showing upon reaching the page
			if (!this.getFirstInvalidFieldElement()) {
				// Set focus to the first element without scrolling
				const firstInput = this.$el.querySelector('input,textarea,select') as HTMLElement;
				if (firstInput)
					firstInput.focus({ preventScroll: true });
			}
		});

		this.pageTransitioning = false;
	}

	confirmationEntered() {
		this.publicEvents.emit(new FormEvent(FormEvents.ScrollToTop));
		if (this.headerRef.$props.visible)
			scrollIntoView(this.headerRef.$el as HTMLElement, { time: 0, align: { topOffset: this.scrollMarginTop } }, null, this.flags.useNativeScrolling);
		else
			scrollIntoView(this.bodyRef, { time: 0, align: { top: 0, topOffset: this.scrollMarginTop } }, null, this.flags.useNativeScrolling);
	}

	/**
	 * Gets the element within the given given component (optional, the form is searched by default)
	 * @param context The component to search for errors (optional)
	 * @returns The first element that represents an error
	 */
	getFirstFieldElement();
	getFirstFieldElement(context: Vue);
	getFirstFieldElement(context: Vue = null) {
		return (context || this).$el.querySelector('input,textarea,select') as HTMLElement;
	}

	/**
	 * Gets the last form element within the given component
	 * @param context The component to search for errors (optional)
	 * @param getTextArea Whether or not to include textarea in your query search (optional)
	 * @returns The last field element within the context
	 */
	getLastFieldElement();
	getLastFieldElement(context: Vue);
	getLastFieldElement(context: Vue = null, getTextArea = false) {
		const base = (context || this);
		if (base.$el.nodeName === '#comment') {
			return null;
		}
		const fields = base.$el.querySelectorAll('input,select' + (getTextArea ? ',textarea' : ''));
		return fields[fields.length - 1];
	}

	/**
	 * Gets the first error element within the given given component (optional, the form is searched by default)
	 * @param context The component to search for errors (optional)
	 * @returns The first element that represents an error
	 */
	getFirstInvalidFieldElement();
	getFirstInvalidFieldElement(context: Vue);
	getFirstInvalidFieldElement(context: Vue = null) {
		return (context || this).$el.querySelector('.is-error, .cog-error-message') as HTMLElement;
	}

	/**
	 * Scrolls to and focuses into (if it is an input) the first error element
	 * @param context The compoent to search for an error to highlight (optional)
	 */
	scrollToAndFocusFirstInvalidFieldElement();
	scrollToAndFocusFirstInvalidFieldElement(context: Vue);
	scrollToAndFocusFirstInvalidFieldElement(context: Vue = null) {
		requestAnimationFrame(()=>{
			const firstError = this.getFirstInvalidFieldElement(context);
			if (firstError) {
				scrollIntoView(firstError, { align: { topOffset: this.scrollMarginTop } }, null, this.flags.useNativeScrolling);
				// According to Evan You, `__vue__` is probably safe to use since vue-dev-tools relies on it - https://github.com/vuejs/vue/issues/5621
				// We could also use the form's `fields` list, but this would require traversing all of the fields, or maintaining a lookup which would be a mem leak risk, and we also wouldn't be aware of other components (like section/table)
				const firstErrorComponent = firstError['__vue__'] as Vue;
				if (firstErrorComponent) {
				// If the first error is on a field with an input focus the first input.
				// Failing that, focus the container of the item in error.
				// Requirement 10207
					const input = firstError.querySelector('input');
					if (firstErrorComponent.$options.name === 'CField' && input)
						input.focus({ preventScroll: true });
					else
						firstError.focus({ preventScroll: true });
				}
			}
		});
	}

	/**
	 * Detects whether or not the current page is valid. If it is not valid, any errors will be forced to
	 * display unless otherwise specified.
	 * @param showErrors Force fields to display validation as if they had been interacted with.
	 */
	assertValidation(showErrors = true) {
		if (!this.enforceValidation)
			return true;

		const fields = this.fields.filter(f => f.pageNumber === this.pageNumber || !this.flags.paging);
		if (showErrors) {
			// Force all fields to display validation
			fields.forEach(f => f.forceValidation());
			// Scroll to and focus in the first error element
			this.$nextTick().then(() => this.scrollToAndFocusFirstInvalidFieldElement());
		}

		const hasErrors = fields.filter(f => f.hasError).length !== 0;

		if (hasErrors)
			this.publicEvents.emit(new FormEvent(FormEvents.FailedValidation));

		return !hasErrors;
	}

	createToastMessage(options: ElMessageOptions) {
		return ToastMessage(this.session.formId, options);
	}

	fileServiceBusy() {
		if (this.fileService && this.fileService.busy) {
			this.createToastMessage({ type: 'error', message: this.$resource('fileupload-upload-inprogress-message') });
			return true;
		}

		return false;
	}

	async authorizeNavigation(targetPage) {
		if (this.fileServiceBusy())
			return false;

		return this.publicEvents.emit(new FormEvent<BeforeNavigateEventData>(FormEvents.BeforeNavigate, {
			entry: this.entry.serialize(),
			direction: targetPage.number > this.pageNumber ? NavigationDirection.Forward : NavigationDirection.Backward,
			sourcePage: {
				title: this.currentPage.title,
				number: this.pageNumber,
				name: 'page' + this.pageNumber
			},
			destinationPage: {
				title: targetPage.title,
				number: targetPage.number,
				name: 'page' + targetPage.number
			}
		}));
	}

	/**
	 * Validates all visible pages between first and last, inclusive.
	 * @param firstPage the first page to validate
	 * @param lastPage the last page to validate
	 */
	async validatePageRange(firstPage: number, lastPage: number) {
		if (!this.enforceValidation)
			return true;

		// Only check visible pages
		const pages = this.pages.slice(firstPage, lastPage + 1).filter(p => p && p.visible);
		for (let i = 0; i < pages.length; i++) {
			const page = pages[i];

			if (!page.current)
				page.virtual = true;

			await this.waitForFieldsToUpdate();

			const errorExists = this.fields.some(f => f.pageNumber === page.number && f.hasError);
			page.virtual = false;

			if (errorExists) {
				this.pageNumber = page.number;

				// Allow page change to take effect
				await this.$nextTick();

				this.assertValidation();
				return false;
			}
		}
		return true;
	}

	async navigateTo(pageNumber: number) {
		const targetPage = this.pages[pageNumber];
		const forward = targetPage.number > this.pageNumber;
		if (pageNumber === this.pageNumber || !targetPage.visible)
			return;

		if (forward && !window.navigator.onLine)
			return;

		// See if page navigation was externally prevented
		if (!(await this.authorizeNavigation(targetPage)))
			return;

		if (pageNumber > this.pageNumber) {
			// Check for errors on the current page
			if (!this.assertValidation())
				return;

			// Check for errors on intermediate pages, don't validate the target page
			if (!(await this.validatePageRange(this.pageNumber + 1, pageNumber - 1)))
				return;
		}
		// Prevent backward navigation if back button is disabled on current or intermediate pages
		else if (this.pages.slice(targetPage.number + 1, this.pageNumber + 1).some(p => !p.backButton))
			return;

		this.pageNumber = pageNumber;
	}

	async navigate({ forward, validateCaptcha = null, action = null }: { forward: boolean, validateCaptcha?: () => boolean, action?: string | null }) {
		if (this.pageTransitioning)
			return;

		if (forward && !window.navigator.onLine && !this.onLastPage) {
			this.publicEvents.emit(new FormEvent(FormEvents.ScrollToTop));
			return;
		}

		if (forward && this.onLastPage) {
			// Set the current action that is being performed (if this call is a result of an action being performed)
			return this.submit({ action, validateCaptcha });
		}

		// Attempt to leave the current page (check validation)
		if (forward && !this.assertValidation())
			return;

		// Find the next visible page
		const direction = forward ? 1 : -1;
		for (let i = this.pageNumber + direction; i >= 0 && i < this.pages.length; i += direction) {
			const targetPage = this.pages[i];
			if (targetPage && targetPage.visible) {
				if (await this.authorizeNavigation(targetPage))
					this.pageNumber = i;
				break;
			}
		}
	}

	fileUploaded(file: FileDataRef) {
		this.publicEvents.emit(new FormEvent<UploadFileEventData>(FormEvents.UploadFile, { file: { id: file.Id, name: file.Name, size: file.Size } }));
	}

	async handleConcurrentEntryConflict(error: ConcurrentEntryConflict) {
		this.entry.markPersisted();
		await this.entry.update(error.entry);
		if (error.order) {
			rebuildOrder(this.entry as EntityOfType<FormEntryWithOrder>, this.order);
			this.showReceipt = false;
		}
	}

	/**
	 * Update the form state on completion of authentication. Try to resubmit
	 * the form upon authentication success.
	 */
	async authenticationComplete(event: AuthenticationCompleteEvent) {
		if (event.success || event.forceResubmit)
			await this.submit();
		else {
			this.paymentError = this.$resource('payment-declined') + ': ' + this.$resource('credit-card-authentication-failed');
			this.submitStatus = FormStatus.Error;
		}
	}

	async canSubmit(validateCaptcha?: () => boolean) {
		try {
			// If a WorkflowAction is being performed on a workflow form, validate that it is allowed
			if (this.flags.workflowEnabled && this.action) {
				if (!this.action.IsAllowed)
					return false;
			}

			if (this.flags.blockSubmission)
				return false;

			if (this.fileServiceBusy())
				return false;

			if (this.quantityService)
				await this.quantityService.refresh();

			// Old Method of Validation
			if (this.flags.paging && !this.flags.modelBasedValidation) {
				if (!(await this.validatePageRange(this.pageNumber, this.pageNumber)))
					return false;

				const firstAccessiblePage = this.getFirstAccessiblePage();

				if (firstAccessiblePage && !(await this.validatePageRange(firstAccessiblePage.number, this.pageNumber - 1)))
					return false;
			}
			// This is for new validation method
			else if (this.flags.modelBasedValidation) {
				if (!this.enforceValidation)
					return true;

				await this.waitForFieldsToUpdate();

				// Validate current page. If current page is invalid return false
				let pageNum = validateByPage(this.entity, this.pageNumber, this.pageNumber);
				if (pageNum === this.pageNumber) {
					this.assertValidation();
					return false;
				}

				// If there are multiple pages, validate the accessible pages
				if (this.pages.length > 1) {
					if (this.flags.paging) {
						const firstAccessiblePage = this.getFirstAccessiblePage(this.submissionPage);
						pageNum = validateByPage(this.entity, firstAccessiblePage.number, this.pageNumber - 1);
					}
					else {
						pageNum = validateByPage(this.entity);
					}
				}

				// If no issues are found, return true
				if (pageNum === 0)
					return true;

				// If there is a QL error on a hidden page, make a toast notification
				if (this.entity[`Page${pageNum}Visible`] === false) {
					this.createToastMessage({ type: 'error', message: this.$resource('entry-submission-error-heading') });
				}
				else {
					// Navigate to the page with the error
					this.pageNumber = pageNum;

					// Allow page change to take effect
					await this.$nextTick();
					this.assertValidation();
				}
				return false;
			}
			else if (!(await this.validatePageRange(0, this.pages.length)))
				return false;

			if (validateCaptcha && !validateCaptcha())
				return false;
		}
		catch {
			return false;
		}

		return true;
	}

	async submit({ validateCaptcha = null, action = null }: { validateCaptcha?: () => boolean, action?: string | null } = {}): Promise<EntrySubmissionResult | undefined> {
		if (this.submitStatus === FormStatus.InProgress)
			return;

		closeAllToastMessages();

		const wasAuthenticating = this.submitStatus === FormStatus.Authenticating;
		this.submitStatus = FormStatus.InProgress;

		// ensure submitStatus watcher executes before moving forward
		await this.$nextTick();

		if (action) {
			this.entry.Attempted_Action = action;
			this.entry.Entry.Action = action;

			// Wait for fields to update after setting the action, since it can trigger validation rules or cause changes to conditional visibility
			await this.waitForFieldsToUpdate();
		}

		if (!await this.canSubmit(validateCaptcha)) {
			this.submitStatus = FormStatus.Default;
			return;
		}

		await this.storeSignatures();

		// Raise submit event
		const entry = this.entry.serialize();

		// beforeSubmitEvent.preventDefault() prevents submission of the form
		const submissionAllowed = await this.publicEvents.emit(new FormEvent<BeforeSubmitEventData>(FormEvents.BeforeSubmit, { entry, hasErrors: false }));
		if (!submissionAllowed) {
			this.submitStatus = FormStatus.Error;
			return;
		}

		const orderComponent = this.$refs.orderComponent;
		const paymentProcessorComponent = this.$refs.paymentProcessorComponent as Vue & PaymentProcessorComponent;

		if (!wasAuthenticating && orderComponent) {
			if (this.processPayment && paymentProcessorComponent) {
				try {
					// If there is no order, then don't attempt to create a token
					if (!this.orderInfo) {
						this.paymentError = this.$resource('order-not-found-message');
						return;
					}

					const token = await paymentProcessorComponent.createToken();

					if (token) {
						if (token.Status === PaymentTokenStatus.AuthenticationFailed)
							this.paymentError = this.$resource('payment-declined') + ': ' + this.$resource('credit-card-authentication-failed');
						this.entry.Entry.PaymentToken = this.formsModel.construct<PaymentToken>('Payment.PaymentToken', token);
					}
					else {
						this.paymentError = 'Unable to create payment token';
						if (this.enforceValidation) {
							this.submitStatus = FormStatus.Error;
							return;
						}
					}
				}
				catch (err) {
					if (this.enforceValidation) {
						this.log && this.log.error(err);
						this.paymentError = (err.message || err) + '';
						this.submitStatus = FormStatus.Error;
						return;
					}
				}
			}
		}

		// If the order is currently open before submitting, show the receipt on the confirmation page
		if (this.order && this.order.IsOpen)
			this.showReceipt = true;

		try {
			let entryReset = false;
			const setEntryResetTrue = () => {
				entryReset = true;
				this.submitStatus = FormStatus.Default;
			};

			this.publicEvents.once(FormEvents.ResetEntry, setEntryResetTrue);

			const result = await this.entryService.submit(this.entry, this.hostingPageUrl, this.internalEntryToken);

			if (entryReset)
				return;

			this.publicEvents.off(FormEvents.ResetEntry, setEntryResetTrue);

			const { entry: finalEntry, order: finalOrder, status, documents, entryToken, message } = result;

			this.internalEntryToken = entryToken;

			if (finalOrder)
				(this.entry as EntityOfType<FormEntryWithOrder>).Order = await this.formsModel.constructAsync<Order & OrderExtensions>('Payment.Order', finalOrder);

			this.entry.Id = finalEntry.Id;
			this.entry.markPersisted();

			await this.entry.update(finalEntry);

			if (this.quantityService && result.status === SubmissionResultStatus.Success)
				this.quantityService.reset({ hasInitialState: true, root: this.entry });

			this.afterSubmit(status, documents, message, result.auditRecordId);

			return result;
		}
		catch (error) {
			this.log && this.log.error(error);
			if (error instanceof CaptchaError)
				this.submitStatus = FormStatus.Captcha;
			else if (error instanceof ConcurrentEntryConflict) {
				this.submitStatus = FormStatus.Default;
				await this.handleConcurrentEntryConflict(error);
				const onlyShowToast = this.processPayment;
				// If the order is open before submitting show a toast and do not resubmit
				if (onlyShowToast) {
					this.createToastMessage({ type: 'error', message: this.$resource('concurrent-conflict-toast-message') });
				}
				else {
					const result = await this.submit({ action, validateCaptcha });
					if (result)
						return result;
					else
						this.createToastMessage({ type: 'error', message: this.$resource('concurrent-conflict-toast-message') });
				}
			}
			else {
				this.submitStatus = FormStatus.Error;
				if (error instanceof InvalidSharedLinkError)
					this.createToastMessage({ type: 'error', message: this.$resource('invalid-shared-link') });
				else if (error instanceof PublicLinksDisabledError)
					this.createToastMessage({ type: 'error', message: this.$resource('form-not-available-message') });
				else if (window.navigator.onLine)
					this.createToastMessage({ type: 'error', message: this.$resource('entry-submission-error-heading') });
			}
		}
	}

	async afterSubmit(status: SubmissionResultStatus, documents, message: string | null | undefined, auditRecordId?: string) {
		if (status === SubmissionResultStatus.Success) {
			this.entry.Attempted_Action = null;
			await this.publicEvents.emit(new FormEvent<AfterSubmitEventData>(FormEvents.AfterSubmit, { entry: this.entry.serialize(), documents, auditRecordId }));
		}

		const redirectOrShowConfirmationPage = (documents) => {
			if (this.flags.submissionSettings) {
				if (this.redirectingToEntryView) {
					this.submitStatus = FormStatus.InProgress;
				}
				else
					this.showConfirmationPage(documents);
			}
			else
				this.submitStatus = FormStatus.Default;
		};

		if (!this.enforceValidation)
			redirectOrShowConfirmationPage(documents);
		else if (status === SubmissionResultStatus.Success) {
			const paymentComponent = this.$refs.paymentComponent as any;
			const paymentProcessorComponent = this.$refs.paymentProcessorComponent as any;
			// eslint-disable-next-line eqeqeq
			if (this.entry.Entry && this.entry.Entry.PaymentToken && this.entry.Entry.PaymentToken.Status == 'PendingAction') {
				this.submitStatus = FormStatus.Authenticating;
				await paymentProcessorComponent.authenticate();
			}
			// eslint-disable-next-line eqeqeq
			else if (this.entry.Entry && this.entry.Entry.PaymentToken && this.entry.Entry.PaymentToken.Status == 'AuthenticationFailed') {
				this.submitStatus = FormStatus.Error;
			}
			// eslint-disable-next-line
			else if (this.order && this.order.PaymentStatus == 'Declined' && paymentComponent && this.processPayment) {
				this.paymentDeclined();
				this.submitStatus = FormStatus.Error;
			}
			else
				redirectOrShowConfirmationPage(documents);
		}
		else {
			this.submitStatus = FormStatus.Error;
			switch (status) {
				case SubmissionResultStatus.AlreadyPaid: {
					this.paymentError = this.$resource('order-exception-order-already-paid');
					break;
				}
				case SubmissionResultStatus.CardDeclined: {
					this.paymentError = this.$resource('card-declined') + ': ' + this.$resource('credit-card-invalid');
					break;
				}
				case SubmissionResultStatus.PaymentDeclined:
				case SubmissionResultStatus.OrderMismatch: {
					this.paymentError = this.$resource('order-exception-default-message');
					break;
				}
				case SubmissionResultStatus.QuantityLimitExceeded: {
					this.quantityService.refresh();

					// scroll to quantity limit validation errors
					this.validatePageRange(0, this.visiblePages.length);
					break;
				}
				case SubmissionResultStatus.AlreadySubmitted:
				case SubmissionResultStatus.Error:
				case SubmissionResultStatus.Unknown: {
					this.createToastMessage({ type: 'error', message: this.$resource('entry-submission-error-heading') });
					break;
				}
			}
		}
	}

	private async redirect(url: string) {
		if (!url.trim())
			return;

		await this.publicEvents.emit(new FormEvent(FormEvents.Redirect, { url }));

		// wait for potential redirect from iframe-form-handle.ts to prevent duplicate
		setTimeout(() => {
			window.location.href = url;
		}, 100);
	}

	isValidUrl(url: string) {
		try {
			// eslint-disable-next-line no-new
			new URL(url);
			return true;
		}
		catch (e) {
			return false;
		}
	}

	resetPaymentError() {
		this.paymentError = null;
	}

	private paymentDeclined() {
		this.paymentError = this.$resource('payment-declined') + (this.order?.PaymentMessage ? ': ' + this.order.PaymentMessage : '');
	}

	async showConfirmationPage(documents: { link: string; title: string; type: string; }[]) {
		this.documents = documents;
		this.showConfirmation = true;
		this.submitStatus = FormStatus.Success;
	}

	private hasPendingAutoSave = false;
	private activeAutoSave: Promise<void>;

	/**
	 * Prevents concurrent autosave requests and deduplicates calls made while a save is in progress.
	 *
	 * For example, if the user triggers an auto save, the save request will be initiated. If they
	 * trigger three more auto saves before the first request completes, only one auto save will occur
	 * after the first request completes.
	 */
	async autoSave(): Promise<void> {
		if (this.hasPendingAutoSave || !this.flags.saveAndResume || !this.isExistingEntry || this.entry.Entry.Status !== 'Incomplete' || !this.session.botValidation)
			return;

		const saveTask = new Deferred<void>();
		if (this.activeAutoSave) {
			this.hasPendingAutoSave = true;
			await this.activeAutoSave;
			this.hasPendingAutoSave = false;
		}

		this.activeAutoSave = saveTask.promise;

		await this.save({ showDialog: false, reportStatus: false });

		this.activeAutoSave = undefined;
		saveTask.resolve();
	}

	async save({ showDialog = this.showSaveAndResumeDialog, reportStatus = true, validateCaptcha = null }: { showDialog? : boolean, reportStatus? : boolean, validateCaptcha?: () => boolean } = {}) {
		// prevent the form from submitting if the user has not been verified
		if (validateCaptcha && !validateCaptcha())
			return;

		closeAllToastMessages();
		if (this.flags.blockSubmission || this.fileServiceBusy() || this.saveStatus === FormStatus.InProgress)
			return;

		const setStatus = status => reportStatus && (this.saveStatus = status);
		setStatus(FormStatus.InProgress);

		try {
			let entryReset = false;
			const setEntryResetTrue = () => {
				entryReset = true;
				this.submitStatus = FormStatus.Default;
			};
			this.publicEvents.once(FormEvents.ResetEntry, setEntryResetTrue);

			await this.storeSignatures();

			const result = await this.entryService.save(this.entry, this.hostingPageUrl, this.internalEntryToken, this.pageNumber);

			if (entryReset)
				return;

			this.publicEvents.off(FormEvents.ResetEntry, setEntryResetTrue);

			const entry = result.entry;
			if (entry) {
				this.entry.Id = entry.Id;
				this.entry.markPersisted();
				await this.entry.update(entry);
			}

			this.internalEntryToken = result.entryToken;

			if (this.flags.submissionSettings && this.isValidUrl(result.link))
				location.hash = parseUrlHash(result.link);

			setStatus(FormStatus.Success);
			this.saveResumeInfo = {
				open: showDialog,
				message: result.message,
				emailMessage: result.emailMessage,
				link: result.link,
				emailAddress: result.emailAddress,
				emailStatus: FormStatus.Default,
				enableSend: result.enableSend
			};
			this.publicEvents.emit(new FormEvent(FormEvents.AfterSave, {
				link: result.link,
				entry: entry
			}));

			return result;
		}
		catch (error) {
			this.log && this.log.error(error);
			if (error instanceof CaptchaError)
				setStatus(FormStatus.Captcha);
			else if (error instanceof ConcurrentEntryConflict) {
				setStatus(FormStatus.Default);
				await this.handleConcurrentEntryConflict(error);

				return this.save({ showDialog, reportStatus });
			}
			else {
				setStatus(FormStatus.Error);
				if (error instanceof InvalidSharedLinkError)
					this.createToastMessage({ type: 'error', message: this.$resource('invalid-shared-link') });
				else if (error instanceof PublicLinksDisabledError)
					this.createToastMessage({ type: 'error', message: this.$resource('form-not-available-message') });
				else if (window.navigator.onLine)
					this.createToastMessage({ type: 'error', message: this.$resource('entry-submission-error-heading') });
			}
		}
	}

	async closeSaveResumeDialog() {
		this.saveResumeInfo.open = false;
	}

	async emailResumeLink(email) {
		this.saveResumeInfo.emailStatus = FormStatus.InProgress;
		try {
			await this.entryService.emailResumeLink(this.entry.Id, email, this.saveResumeInfo.emailMessage, this.hostingPageUrl);
			this.saveResumeInfo.emailStatus = FormStatus.Success;
			this.createToastMessage({ type: 'success', message: this.$resource('save-and-resume-email-sent-message') });
		}
		catch (error) {
			this.log && this.log.error(error);
			this.saveResumeInfo.emailStatus = FormStatus.Error;
			this.createToastMessage({ type: 'error', message: this.$resource('save-and-resume-email-not-sent-message') });
		}
	}

	async waitForFieldsToUpdate() {
		// Initial field count and wait
		let fieldCount;
		await this.$nextTick();

		// Wait until the field count is no longer changing
		do {
			fieldCount = this.fields.length;

			await Promise.all(_asyncComponentImports);
			await this.$nextTick();
		}
		while (this.fields.length !== fieldCount);
	}

	storeSignatures() {
		const uploads = new Map<Entity, Promise<any>>();

		visitEntity(this.entry, (entity, _, parentProperty) => {
			if (isSignature(entity) && !uploads.has(entity)) {
				// clear file properties if flag is disabled to ensure data URI properties are serialized
				if (!this.session.flags.offloadSignatureData) {
					// if the Png property no longer contains the id of the file, the signature was changed
					if (entity.PngFile && entity.PngFile.Id !== entity.Png) {
						entity.PngFile = null;
						entity.SvgFile = null;
					}
					uploads.set(entity, Promise.resolve());
				}
				// if the Png property still contains the id of the file, the signature was not changed, no need to upload anything
				else if (entity.PngFile && entity.PngFile.Id === entity.Png)
					uploads.set(entity, Promise.resolve());
				else {
					uploads.set(entity, this.fileService.uploadSignature(entity.Png, entity.Svg)
						.then(({ png, svg }) => {
							if (png) {
								entity.PngFile = png;
								entity.Png = png.Id;
							}
							if (svg) {
								entity.SvgFile = svg;
								entity.Svg = svg.Id;
							}
						}).catch(reason => {
							this.log.warn(`Signature upload failed due to ${reason}.`, null, {
								signatureField: parentProperty!.name,
								png: entity.Png?.substring(0, 100) + '...',
								svg: entity.Svg?.substring(0, 100) + '...'
							});
							entity.PngFile = null;
							entity.SvgFile = null;
							return Promise.resolve();
						}));
				}
			}
		}, { followCircularProperties: false, followLookups: false });
		return Promise.all(uploads.values());
	}

	getFirstAccessiblePage(lastPage?) {
		let tempPage = lastPage || null;
		for (const p of this.visiblePages.slice().reverse()) {
			if (p.backButton)
				tempPage = p;
			else if (p === this.visiblePages[0])
				return p;
			else
				return tempPage;
		}
	}
};