var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "c-button",
    {
      staticClass: "cog-button--has-status",
      class: { ["is-" + _vm.internalStatus]: _vm.internalStatus !== "default" },
      scopedSlots: _vm._u(
        [
          {
            key: "icon",
            fn: function() {
              return [_vm._t("icon")]
            },
            proxy: true
          },
          {
            key: "icon2",
            fn: function() {
              return [_vm._t("icon2")]
            },
            proxy: true
          },
          {
            key: "status-icon",
            fn: function() {
              return [
                _vm.internalStatus === "validating" ||
                _vm.internalStatus === "authenticating"
                  ? _c("i-cog", {
                      staticClass: "cog-button__status-icon is-validating"
                    })
                  : _vm.internalStatus === "success"
                  ? _c("i-check", {
                      staticClass: "cog-button__status-icon is-success"
                    })
                  : _vm.internalStatus === "error"
                  ? _c("i-error", {
                      staticClass: "cog-button__status-icon is-error"
                    })
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [_vm._v(" "), _vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }