<template>
	<div :class="['cog-col ' + colNumClass]">
		<slot />
	</div>
</template>

<script>
	export default {
		name: 'CCol',
		props: {
			cols: {
				type: Number,
				default: 24
			},
			parentCols: {
				type: Number,
				default: 24
			}
		},
		computed: {
			colNumClass() {
				return `cog-col--${Math.round((this.$props.cols / this.$props.parentCols) * 24)}`;
			}
		}
	};
</script>