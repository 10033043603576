<template>
	<component
		:is="paging ? 'transition' : 'visible-transition'"
		v-if="!virtual"
		v-bind="paging ? {
			name: 'cog-page'
		} : null"
		v-on="paging ? {
			'before-enter': beforeEnter,
			enter: onEnter,
			'after-enter': afterEnter
		} : null"
	>
		<div
			v-if="current && canRender"
			ref="page"
			class="cog-page cog-wrapper"
			:class="'cog-transition-' + transition"
			:data-page="hideNumber || (isSubmission && visibleNumber === 1) ? false : visibleNumber"
		>
			<slot />

			<Captcha v-if="(isSubmission || showSaveButton) && !session.botValidation && session.botChallenge" ref="captcha" :bot-challenge="session.botChallenge" @validated="captchaValidated" />

			<div
				v-if="(showNavigation && hasButtons && paging) || (showNavigation && hasButtons && isSubmission)"
				ref="navigation"
				class="cog-page__navigation"
				:class="{
					'cog-page__navigation--condensed': navIsCondensed,
					'is-being-measured': measuringIfNavIsCondensed
				}"
			>
				<c-button v-if="showBackButton" class="cog-button--secondary cog-button--navigation cog-button--back" @click.native="$emit('back')">
					<template #icon><i-back /></template>
					{{ avoidOrphans(backButton) }}
				</c-button>
				<template v-if="isSubmission && flags.workflowEnabled">
					<c-status-button
						v-for="action in allowedActions"
						:key="action.ActionName"
						data-next
						:data-action-name="action.ActionName"
						type="submit"
						class="cog-button--primary cog-button--navigation cog-button--submit"
						:status="getActionStatus(action)"
						@click.prevent.native="handleSubmitClick"
					>
						{{ avoidOrphans(action.ButtonText) }}
					</c-status-button>
				</template>
				<c-status-button
					v-else
					data-next
					:type="nextButtonType"
					class="cog-button--primary cog-button--navigation"
					:class="{'cog-button--submit': isSubmission, 'cog-button--next': !isSubmission }"
					:status="submitStatus"
					@click.prevent.native="isSubmission ? handleSubmitClick($event) : handleNext()"
				>
					<template v-if="!isSubmission" #icon2><i-next /></template>
					{{ isSubmission ? avoidOrphans(submitButtonText) : avoidOrphans(nextButton) }}
				</c-status-button>
				<c-status-button v-if="showSaveButton" class="cog-button--save cog-button--secondary cog-button--navigation" :status="saveStatus" @click.native="handleSave">{{ saveButtonText }}</c-status-button>
			</div>
			<div v-if="showSubmissionWarning && isSubmission" class="cog-submission-warning">{{ $resource('public-submission-warning') }}</div>
		</div>
	</component>
	<div v-else class="hide">
		<slot :virtual="virtual" />
	</div>
</template>

<script>
	import CButton from './Button.vue';
	import CStatusButton from './StatusButton.vue';
	import Captcha from './Captcha.vue';
	import { FormStatus, FormStatusProp } from 'src/mixins/form-status';
	import IBack from '../assets/back.svg';
	import INext from '../assets/next.svg';
	import { uiDebounce } from 'src/util/debounce';

	export default {
		name: 'CPage',
		components: { CButton, CStatusButton, Captcha, IBack, INext },
		inject: ['session', 'flags', 'form', '$resource'],
		provide() {
			return {
				pageNumber: this.number
			};
		},
		props: {
			title: { type: String, default: '' },
			number: { type: Number, default: 1 },
			visibleNumber: { type: Number, default: 1 },
			transition: {
				type: String,
				default: 'ltr',
				validator: value => {
					return value === 'ltr' || value === 'up' || value === 'fade' || value === 'zoom' || value === 'flip';
				}
			},
			current: { type: Boolean, default: true },
			visible: { type: Boolean, default: true },
			hideNumber: { type: Boolean, default: false },
			showNavigation: { type: Boolean, default: true },
			paging: { type: Boolean, default: true },
			backButton: { type: String, default: '' },
			nextButton: { type: String, default: 'Submit' },
			submitButton: { type: String, default: 'Submit' },
			allowSave: { type: Boolean, default: false },
			isSubmission: { type: Boolean, required: false, default: false },
			allowedActions: { type: Array, required: false, default() { return []; } },
			currentAction: { type: Object, default: null },
			submitStatus: FormStatusProp,
			saveStatus: FormStatusProp,
			showSubmissionWarning: { type: Boolean, default: true }
		},
		data() {
			return {
				virtual: false,
				navIsCondensed: false,
				debouncedCheckButtonSpace: uiDebounce(() => this.checkButtonSpace()),
				navContainerWidth: null,
				measuringIfNavIsCondensed: false,
				lastTextInput: null,
				observer: null,
				canRender: this.visible
			};
		},
		computed: {
			showBackButton() {
				return !!this.backButton;
			},
			showSaveButton() {
				const canSave = this.allowSave && this.submitStatus !== FormStatus.InProgress;
				let isPublicRole = true;
				let isIncomplete = true;

				if (this.form.entry && this.form.entry.Entry) {
					isPublicRole = this.flags.workflowEnabled ? this.form.entry.Entry.Role === this.session.publicRole : true;
					isIncomplete = this.form.entry.Entry.Status === 'Incomplete';
				}

				return canSave && isPublicRole && isIncomplete;
			},
			nextButtonType() {
				return this.isSubmission ? 'submit' : 'button';
			},
			submitButtonText() {
				return !this.submitButton || !this.submitButton.trim() ? this.$resource('submit-button-text') : this.submitButton;
			},
			saveButtonText() {
				return this.$resource('save-button-text') || 'Save';
			},
			hasButtons() {
				if (!this.flags.workflowEnabled)
					return true;

				const hasActions = this.allowedActions.length > 0;

				// If the form has action buttons for the last page, isn't on the last page (next button), has a save button, or has or a back button
				return ((hasActions || !this.isSubmission) || this.showSaveButton || this.showBackButton);
			}
		},
		watch: {
			visible() {
				// If this is the current page, allow it to be rendered even if it becomes invisible
				if (!this.current || !this.paging)
					this.canRender = this.visible;
			},
			current() {
				this.canRender = this.visible;
			},
			saveStatus(status) {
				// show captcha
				if (status === FormStatus.Captcha)
					this.$refs.captcha.validateUser(true);
			},
			submitStatus(status) {
				// show captcha
				if (status === FormStatus.Captcha)
					this.$refs.captcha.validateUser(true);
			}
		},
		mounted() {
			if (this.form)
				this.form.registerPage(this);

			this.debouncedCheckButtonSpace();
			window.addEventListener('resize', this.debouncedCheckButtonSpace);
			this.registerObserver();
		},
		beforeLeave() {
			window.removeEventListener('resize', this.debouncedCheckButtonSpace);
			this.observer.disconnect();
		},
		beforeDestroy() {
			if (this.form)
				this.form.unregisterPage(this);
		},
		methods: {
			beforeEnter() {
				this.$emit('beforeEnter');
				this.checkButtonSpace(false);
			},
			onEnter() {
				this.$emit('entering', this.$refs.page.clientHeight);
			},
			afterEnter() {
				this.registerObserver();
				this.$emit('entered');
			},
			captchaValidated(evidence) {
				this.session.isHuman(evidence);
			},
			getActionStatus(action) {
				return action === this.currentAction ? this.submitStatus : FormStatus.Default;
			},
			handleSave(e) {
				this.$emit('save', this.$refs.captcha ? { validateCaptcha: this.getCaptchaValidator() } : {});
			},
			handleNext() {
				this.$emit('next', {});
			},
			handleSubmitClick(event) {
				this.$emit('clicked-submit', event);
			},
			registerObserver() {
				if (!this.current)
					return;
				// find all text input elements
				this.includeGoButton();

				if (!this.observer) {
					this.observer = new MutationObserver(() => {
						this.$nextTick(()=>{
							this.includeGoButton();
						});
					});
				}
				this.observer.observe(this.$el, { childList: true, subtree: true });
			},
			includeGoButton() {
				const lastElement = this.form.getLastFieldElement(this, true);

				if (this.lastTextInput) this.lastTextInput.removeAttribute('enterKeyHint');
				if (lastElement && lastElement.className.includes('el-input__inner')) {
					lastElement.enterKeyHint = 'Go';
					this.lastTextInput = lastElement;
				}
			},
			getCaptchaValidator() {
				const captcha = this.$refs.captcha;
				return captcha ? () => captcha.validateUser() : null;
			},
			avoidOrphans(text) {
				// If the last two words are not that long, put a nbsp; between them so
				// one word is not orphaned on the last line.
				const words = text.split(' ');
				const lastTwoWords = words.splice(-2).join('\xa0');
				return lastTwoWords.length < 20 ? words.join(' ') + ' ' + lastTwoWords : text;
			},
			checkButtonSpace(resize = true) {
				const navContainer = this.$refs.navigation;
				if (!navContainer || (resize && navContainer.clientWidth === this.navContainerWidth))
					return;
				else if (resize)
					this.navContainerWidth = navContainer.clientWidth;

				this.navIsCondensed = false;

				this.$nextTick(()=>{
					if (navContainer.clientWidth >= 600)
						return;
					// Don't run for inactive pages
					if (this.$el.nodeType === Node.COMMENT_NODE)
						return;

					const firstButton = navContainer.querySelector('button');
					if (!firstButton)
						return;

					this.measuringIfNavIsCondensed = true;
					this.$nextTick(()=>{
						navContainer.style.display = 'flex'; // For Chameleon
						navContainer.style.flexWrap = 'wrap';
						const buttonsNeedSpace = Math.round(navContainer.getBoundingClientRect().bottom) !== Math.round(firstButton.getBoundingClientRect().bottom);
						this.navIsCondensed = buttonsNeedSpace;
						navContainer.style.display = null;
						navContainer.style.flexWrap = null;
					});
				});
				setTimeout(()=> {
					this.measuringIfNavIsCondensed = false;
				}, 500);
			}
		}
	};
</script>

<style lang="scss">
#{$specificity-base} {

	.cog-form--show-all-pages {

		.cog-page {
			transition: none !important;
		}

		.cog-page:not(:last-child) .cog-page__navigation {
			display: none;
		}

		.cog-page + .cog-page {
			// Since fields have a quarter gutter margin around them,
			// the subsequent page won't start quite at the top, hence the calculation.
			margin-top: calc(var(--gutter) - #{$gutter-quarter});
		}
	}

	.cog-page {
		width: 100%;

		&::after {
			display: block;
			clear: both;
			content: '';
		}

		&[data-page]::after {
			padding-top: $gutter-half;
			content: attr(data-page);
			font-size: var(--small-text);
			text-align: right;
		}

		&.cog-page-enter-active,
		&.cog-page-leave-active {
			transition: all ease-in-out var(--speed);
		}
	}

	// The leaving page must retain its height long enough for JS to measure it.
	// JS will apply .cog-page-transition class to cog-body after it is done measuring.
	// All this fancy transitioning doesn't apply after the form has been submitted.
	.cog-form:not(.is-success) .cog-page.cog-transition-enter-active,
	.cog-form:not(.is-success) .cog-page-transition .cog-page {
		position: absolute;
	}

	.cog-transition-ltr {
		// Going forward
		&.cog-page-enter {
			opacity: 0;
			transform: translateX(-120%);
		}

		&.cog-page + .cog-transition-ltr.cog-page-leave-to {
			transform: translateX(100%);
		}

		// Going backward
		&.cog-page + .cog-transition-ltr.cog-page-enter {
			transform: translateX(100%);
		}

		&.cog-page-leave-to {
			opacity: 0;
			transform: translateX(-120%);
		}
	}

	// Page styles after the form has been submitted
	.is-validating .cog-page {
		opacity: .4;
	}

	// Instantly hide last page and allow confirmation to fade in.
	.is-success:not(.cog-form--confirmation-has-entry-details) .cog-page:not(.cog-confirmation) {
		display: none;
	}

	.cog-form--confirmation-has-entry-details .cog-page {
		opacity: 0;
		animation-name: cog-fade-in;
		animation-duration: $speed-half;
		animation-fill-mode: forwards;
		transition: none;

		&[data-page]::after {
			content: none;
		}

		&.cog-page-leave-active {
			animation: none;
		}
	}

	.cog-form--confirmation-has-entry-details .cog-page:not(.cog-confirmation) {
		animation-delay: var(--speed);
	}

	.grecaptcha-badge {
		display: none;
	}

	.cog-page__navigation {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		padding-top: $gutter-half;

		.cog-button {
			margin-top: $gutter-half;
		}

		@include at-root(-1) {

			.cog-page__navigation .cog-button:not(:last-child) {
				margin-right: $gutter-half;
			}
		}

		&.is-being-measured .cog-button--navigation {
			transition: none;
		}
	}

	.cog-button--save {
		margin-left: auto;
	}

	.cog-submission-warning {
		padding-top: $gutter-half;
		font-size: var(--small-text);
	}

	.cog-page__navigation--condensed .cog-button {
		width: 100%;
		margin-right: 0;
	}
}
</style>