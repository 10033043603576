import { processCssVars } from 'src/mixins/css-vars';
import type { FormDefinitionTheme } from './form-definition';

function createThemeStyle(formId: string, css: string, className: string = 'cog-css-variables'): HTMLStyleElement {
	const style = document.createElement('style');
	style.innerHTML = css;
	style.setAttribute('data-form-id', formId);
	if (className)
		style.setAttribute('class', className);
	document.head.appendChild(style);
	return style;
}

function updateStyleRules(style: HTMLStyleElement, css: string): void {
	if (style.innerHTML !== css) {
		style.innerHTML = css;
		processCssVars({ cleanSlate: true });
	}
}

function createThemeStylesheet(formId: string, url: string, className: string = 'cog-theme-link'): HTMLLinkElement {
	const link = document.createElement('link');
	link.rel = 'stylesheet';
	link.href = url;
	link.setAttribute('data-form-id', formId);
	if (className)
		link.setAttribute('class', className);
	document.head.appendChild(link);
	return link;
}

function removeElement(element: HTMLElement): void {
	element.parentElement.removeChild(element);
}

export default class FormTheme {
	formId: string;
	style: HTMLStyleElement;
	links: HTMLLinkElement[];
	isChameleon: boolean;

	private constructor() {}

	static create(formId: string, themeData: FormDefinitionTheme): FormTheme {
		// Create the style tag for theme variables
		const style = createThemeStyle(formId, themeData.css);

		// Inject stylesheet links
		const links = themeData.links.map(url => createThemeStylesheet(formId, url));

		const theme = new FormTheme();
		Object.assign(theme, {
			formId,
			links,
			style,
			isChameleon: themeData.isChameleon
		});
		return theme;
	}

	updateStyles(css: string, linkUrls: string[]): void {
		updateStyleRules(this.style, css);
		this.links.forEach(link => removeElement(link));
		this.links = linkUrls.map(url => createThemeStylesheet(this.formId, url));
	}

	destroy() {
		// Remove the style tag from the DOM
		if (this.style)
			this.style.remove();

		// Remove any style links from the DOM
		for (const link of this.links) {
			link.remove();
		}
	}
}
